import React from "react";

export default function Price({ children }) {
  if (!children) return "0.00";
  let formattedNumber = parseFloat(children).toFixed(2);
  if (formattedNumber.endsWith(".00")) {
    formattedNumber = formattedNumber.slice(0, -3);
  }

  formattedNumber = formattedNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return <span>{formattedNumber}</span>;
}

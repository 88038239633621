import { useEffect, useState } from "react";
import emptyBagPNG from "../assets/images/empty-bag.png";
import Filters from "../components/common/filters";
import { default as SideFilters } from "../components/filters/SideFilters";
import HomeLoading from "../components/loadings/HomeLoading";
import Pagination from "../components/pagination/Pagination";
import ProductList from "../components/products/ProductList";
import SearchBar from "../components/products/SearchBar";
import useProductFilter from "../hooks/useProductFilter";
import useSellerStore from "../hooks/useSellerStore";
import ProductServices from "../services/ProductServices";
import productUtils from "../utils/product.utils";
export default function Home() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const store = useSellerStore();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await ProductServices.get();
        setData(res.data);
        setLoading(false);
        const ids = productUtils.getProductIds(res.data.content);
        const images = await ProductServices.getProductImages(ids);
        const medias = images.data.content;
        const products = res.data.content;
        const updatedProductsImages = productUtils.updateProductImages(
          medias,
          products
        );
        setData((prev) => ({
          ...prev,
          content: updatedProductsImages,
        }));
      } catch (err) {
        console.log(err);
      }
    };

    fetchProducts();
  }, []);

  const {
    searchResult,
    search,
    setSearch,
    category,
    setCategory,
    page,
    setPage,
    brand,
    setBrand,
    collection,
    setCollection,
  } = useProductFilter(data);

  return (
    <div>
      <SearchBar setSearch={setSearch} search={search} />
      <div className="grid lg:grid-cols-5 Order px-6 sm:px-6 lg:px-8 py-6 gap-6">
        <div className="hidden lg:block ">
          <SideFilters
            options={store?.categories}
            setter={setCategory}
            getter={category}
            name="Categories"
          />
          <SideFilters
            options={store?.brands}
            setter={setBrand}
            getter={brand}
            name="Brands"
          />
          <SideFilters
            options={store?.collections}
            setter={setCollection}
            getter={collection}
            name="Collections"
          />
        </div>
        <main className="lg:col-span-4">
          <div className="flex items-center gap-2">
            {category && (
              <Filters
                filter={"Category"}
                name={category?.name}
                onRemove={() => setCategory(null)}
              />
            )}
            {brand && (
              <Filters
                filter={"Brand"}
                name={brand?.name}
                onRemove={() => setBrand(null)}
              />
            )}{" "}
            {collection && (
              <Filters
                filter={"Collection"}
                name={collection?.name}
                onRemove={() => setCollection(null)}
              />
            )}
          </div>
          <div className="  ">
            {loading ? (
              <HomeLoading />
            ) : searchResult?.content?.length > 0 ? (
              <div>
                <ProductList
                  products={searchResult?.content}
                  extras={data?.extras}
                />
                <div className="mt-4 flex justify-end px-4 lg:px-0">
                  <Pagination
                    page={searchResult?.page}
                    totalPages={searchResult?.total_pages}
                    isFirstPage={searchResult?.is_first_page}
                    isLastPage={searchResult?.is_last_page}
                    currentPage={searchResult?.current_page}
                    onChange={(e) => setPage(e.selected)}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center mt-20">
                <img src={emptyBagPNG} alt="empty bag" className="w-72" />
                <p className="text-sm text-gray-600">
                  This supplier has no products to show!
                </p>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

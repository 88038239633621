import getSymbolFromCurrency from "currency-symbol-map";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import { MdShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import Price from "../components/products/Price";
import ProductImage from "../components/products/ProductImage";
import useSellerStore from "../hooks/useSellerStore";
import { useCart } from "../providers/CartProvider";
export default function Cart() {
  const {
    cart,
    totalVat,
    subtotal,
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
  } = useCart();
  const store = useSellerStore();

  console.log({ cart });

  return (
    <>
      {cart?.length > 0 ? (
        <div className="max-w-6xl mx-auto py-6 px-4 sm:px-0">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 ">
            Cart
          </h1>
          <main className="bg-white mt-4 ">
            <div className="lg:p-14 mx-auto py-6 px-4 sm:py-10 sm:px-6 ">
              <h2>Review Order</h2>
              <div className="mt-12">
                <section aria-labelledby="cart-heading">
                  <h2 id="cart-heading" className="sr-only">
                    Items in your shopping cart
                  </h2>

                  <ul className=" border-b border-gray-200 divide-y divide-gray-200">
                    {cart?.map((product) => (
                      <li key={product.id} className="flex py-6">
                        <div className="flex-shrink-0">
                          <ProductImage
                            className="w-24 h-24 rounded-md object-center object-cover sm:w-32 sm:h-32"
                            image={product.image}
                          />
                        </div>

                        <div className="ml-4 flex-1 flex flex-col sm:ml-6">
                          <div className="flex justify-between">
                            <div className="">
                              <h4 className="">
                                <a
                                  href={product.href}
                                  className="text-sm font-medium text-gray-900 hover:text-gray-800"
                                >
                                  {product.title}
                                </a>
                              </h4>

                              <div className="flex flex-col gap-2  mt-2 text-sm">
                                <h6 className="text-xs">Quantity:</h6>
                                <div className="flex items-center gap-2">
                                  <button
                                    onClick={() => decreaseQuantity(product)}
                                  >
                                    <AiFillMinusCircle className="w-5 h-5" />
                                  </button>
                                  <span className="text-xs">
                                    {product.quantity}
                                  </span>
                                  <button
                                    onClick={() => increaseQuantity(product)}
                                  >
                                    <AiFillPlusCircle className="w-5 h-5" />
                                  </button>
                                </div>
                              </div>

                              {product.variants?.map((v) => (
                                <p className="mt-2 text-sm text-gray-600 ">
                                  <span className="text-gray-500">
                                    {v.variant_name}:{" "}
                                  </span>
                                  : {v.entry}
                                </p>
                              ))}
                            </div>

                            <div className="flex flex-col justify-between h-full">
                              <button
                                className="flex self-end"
                                onClick={() => removeFromCart(product)}
                              >
                                <IoCloseSharp />
                              </button>
                              <p className="ml-4 text-sm font-medium text-gray-900">
                                {getSymbolFromCurrency(store?.currency_code)}
                                <Price>
                                  {product.variants
                                    ? product.selectedComb.price *
                                      product.quantity
                                    : product?.price * product.quantity}
                                </Price>
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </section>

                {/* Order summary */}
                <section aria-labelledby="summary-heading" className="mt-10">
                  <h2 id="summary-heading" className="sr-only">
                    Order summary
                  </h2>

                  <div>
                    <dl className="space-y-4">
                      <div className="flex items-center justify-between">
                        <dt className="text-sm font-medium text-gray-900">
                          VAT
                        </dt>
                        <dd className="ml-4 text-sm font-medium text-gray-900">
                          {getSymbolFromCurrency(store?.currency_code)}
                          <Price>{totalVat}</Price>
                        </dd>
                      </div>
                    </dl>
                    <dl className="space-y-4 border-b pb-4">
                      <div className="flex items-center justify-between">
                        <dt className="font-medium text-gray-900">Subtotal</dt>
                        <dd className="ml-4 mt-6 font-medium text-gray-900">
                          {getSymbolFromCurrency(store?.currency_code)}
                          <Price>{subtotal}</Price>
                        </dd>
                      </div>
                    </dl>
                  </div>

                  <div className="mt-10 flex gap-8 items-center justify-end">
                    <Link to={"/"}>
                      <a href="#" className="text-black text-sm font-medium ">
                        Continue Shopping
                      </a>
                    </Link>
                    <Link to={"/checkout"}>
                      <button
                        type="submit"
                        className=" bg-black border border-transparent shadow-sm py-3 px-16 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                      >
                        Checkout
                      </button>
                    </Link>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div className=" min-h-screen bg-white">
          <div className="max-w-6xl mx-auto py-6">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 ">
              Cart
            </h1>
            <div className="mt-6 py-20 bg-[#F9F9F9] ">
              <div className="max-w-sm mx-auto flex flex-col justify-center items-center">
                <MdShoppingCart className="w-28 h-28 text-[#DCE4EF]" />
                <p className="font-medium">Your Cart is Empty!</p>
                <p className="text-center py-4 text-sm lg:px-12">
                  Click the button below to start shopping and add items to your
                  cart.
                </p>
                <Link to={"/"}>
                  <button
                    type="submit"
                    className="rounded bg-black border border-transparent shadow-sm py-2 px-8 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  >
                    Start Shopping
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

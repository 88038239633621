import React from "react";

export default function OrderLoaing() {
  return (
    <div
      role="status"
      class="mt-4 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse "
    >
      <div class="flex items-center justify-between py-3">
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
      </div>
      <div class="flex items-center justify-between py-3">
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
      </div>
      <div class="flex items-center justify-between py-3">
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
      </div>
      <div class="flex items-center justify-between py-3">
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
      </div>
      <div class="flex items-center justify-between py-3">
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
        <div class="h-4 bg-gray-300 rounded-full  w-1/6 "></div>
      </div>

      <span class="sr-only">Loading...</span>
    </div>
  );
}

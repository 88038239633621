import { Listbox } from "@headlessui/react";
import classNames from "classnames";

export default function Select({
  options = [],
  Controller,
  control,
  name,
  placeholder,
  unSelect = true,
  buttonClassName,
}) {
  const classes = {
    root: "border border-gray-200  px-3 h-10 rounded flex justify-between gap-2 w-full items-center text-sm bg-white focus:outline-none pr-2 border-r  whitespace-no-wrap overflow-hidden",
    value: "text-gray-900 ",
    noValue: "text-gray-400 ",
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Listbox value={value} onChange={onChange}>
          <Listbox.Button
            className={classNames(
              classes.root,
              {
                [classes.value]: value,
                [classes.noValue]: !value,
              },
              buttonClassName
            )}
          >
            {value
              ? options.find((o) => o.id === value)?.label
              : placeholder || "Select"}

            <svg
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.76822 5.07813C4.36843 5.55789 3.63157 5.55789 3.23178 5.07813L0.366819 1.64018C-0.175953 0.988856 0.287204 1.91977e-07 1.13504 2.66098e-07L6.86496 7.67023e-07C7.7128 8.41143e-07 8.17595 0.988859 7.63318 1.64019L4.76822 5.07813Z"
                fill="#43495C"
              />
            </svg>
          </Listbox.Button>
          <Listbox.Options className=" absolute z-50 w-full h- h-auto  py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {unSelect && (
              <Listbox.Option
                value={null}
                className={({ active, selected }) =>
                  `
            ${selected && "bg-green-500 text-white"} 
            cursor-default select-none relative py-2 pl-3 pr-4 gap-2 hover:bg-green-200  
            ${active ? "bg-green-900 text-white" : ""}`
                }
              >
                Select
              </Listbox.Option>
            )}
            {options &&
              options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  value={option.value}
                  disabled={option.disabled}
                  className={({ active, selected }) =>
                    `
            ${selected && "bg-black text-white "} 
            cursor-default select-none relative py-2 pl-3 pr-4 gap-2 hover:bg-gray-700
            ${active ? "bg-gray-700 text-white" : ""}`
                  }
                >
                  {option.label}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Listbox>
      )}
    />
  );
}

import { Dialog, Tab, Transition } from "@headlessui/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { Fragment, useEffect, useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiShoppingBag } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import ProductServices from "../../services/ProductServices";
import productUtils from "../../utils/product.utils";
import { notifyError } from "../../utils/toast";
import AddToCartButton from "../common/AddToCartButton";
import Price from "./Price";
import ProductImage from "./ProductImage";
import SelectVariant from "./SelectVariant";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function QuickView({
  open,
  setOpen,
  product,
  extras,
  addToCart,
}) {
  const navigate = useNavigate();
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [qty, setQty] = useState(1);
  const [images, setImages] = useState([]);
  const variantPrice = useMemo(() => {
    return productUtils.getVariantPrice(product, selectedVariant);
  }, [product, selectedVariant]);

  const onSales = productUtils.onSales(product);

  const addToCartHandler = (buyNow) => {
    if (selectedVariant.length < product?.variants.length) {
      notifyError("Variant is missing");
      return;
    }
    addToCart(product, qty, selectedVariant);
    setSelectedVariant([]);
    setQty(1);
    if (buyNow) {
      navigate("/cart");
    }
    setOpen(false);
  };

  useEffect(() => {
    if (product) {
      const id = product.id;
      ProductServices.getProductImage(id)
        .then((res) => {
          const images = res.data.content;
          setImages(images);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [product]);

  const increaseQuantity = () => {
    setQty(qty + 1);
  };

  const decreaseQuantity = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div
          className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
          style={{ fontSize: 0 }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:align-middle md:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
              <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>

                  {/* <XIcon className="h-6 w-6" aria-hidden="true" /> */}
                  <AiOutlineClose />
                </button>

                <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8 px-4">
                  {/* Image gallery */}
                  <div className="sm:col-span-4 lg:col-span-5">
                    {images?.length === 0 ? (
                      <HiShoppingBag
                        className={` w-full object-center h-80 object-contain text-gray-200 `}
                      />
                    ) : (
                      <Tab.Group as="div" className="flex flex-col-reverse">
                        {/* Image selector */}
                        <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                          <Tab.List className="grid grid-cols-4 gap-6">
                            {images?.map((image) => (
                              <Tab
                                key={image.media_id}
                                className="relative aspect-square bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                              >
                                {({ selected }) => (
                                  <>
                                    <span className="sr-only">
                                      {image.file_name}
                                    </span>
                                    <span className="absolute inset-0 rounded-md overflow-hidden">
                                      <ProductImage
                                        image={image.url}
                                        alt={image.file_name}
                                        className="aspect-square object-center object-cover"
                                      />
                                    </span>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "ring-indigo-500"
                                          : "ring-transparent",
                                        "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </Tab>
                            ))}
                          </Tab.List>
                        </div>

                        <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                          {images?.map((image) => (
                            <Tab.Panel key={image.media_id}>
                              <ProductImage
                                image={image.url}
                                alt={image?.file_name}
                                className="w-full h-full object-center object-cover"
                              />
                            </Tab.Panel>
                          ))}
                        </Tab.Panels>
                      </Tab.Group>
                    )}
                  </div>
                  <div className="sm:col-span-8 lg:col-span-7">
                    <h2 className="text-xl  text-gray-900 sm:pr-12">
                      {product?.title}
                    </h2>

                    <section
                      aria-labelledby="information-heading"
                      className="mt-3"
                    >
                      <h4 className="sr-only">Price</h4>
                      <p className="text-xl text-gray-900">
                        {onSales && (
                          <span className="line-through font-light">
                            {getSymbolFromCurrency(extras?.currency_code)}
                            <Price>{variantPrice || product?.price}</Price>
                          </span>
                        )}

                        <span className="font-normal ml-3">
                          {getSymbolFromCurrency(extras?.currency_code)}
                          <Price>{variantPrice || product?.price}</Price>
                        </span>
                      </p>

                      <div className="mt-6">
                        <h6 className="text-xs mb-1">Quantity</h6>
                        <AddToCartButton
                          quantity={qty}
                          increaseQuantity={increaseQuantity}
                          decreaseQuantity={decreaseQuantity}
                        />
                      </div>

                      <div className="mt-6">
                        {product?.variants?.map((variant) => (
                          <SelectVariant
                            key={variant.id}
                            variant={variant}
                            setSelectedVariant={setSelectedVariant}
                            selectedVariant={selectedVariant}
                          />
                        ))}
                      </div>

                      <div className="mt-6">
                        <h4 className="sr-only">Description</h4>

                        <div
                          className="text-sm text-gray-700"
                          dangerouslySetInnerHTML={{
                            __html: product?.description,
                          }}
                        />
                      </div>
                    </section>

                    <section aria-labelledby="options-heading" className="mt-6">
                      <h3 id="options-heading" className="sr-only">
                        Product options
                      </h3>

                      <form>
                        <div className="flex items-center gap-4">
                          <div className="mt-6">
                            <button
                              type="button"
                              onClick={() => addToCartHandler()}
                              className="w-full bg-black border border-transparent py-3 px-8 flex items-center justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                            >
                              Add to Cart
                            </button>
                          </div>

                          <div className="absolute top-4 left-10 text-center sm:static sm:mt-6">
                            <button
                              type="button"
                              onClick={() => {
                                addToCartHandler("navigate");
                              }}
                              className="font-medium text-sm text-black"
                            >
                              Buy Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

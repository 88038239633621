import requests from "./httpsServices";

const BuyerServices = {
  get(id, key) {
    return requests.get(
      `/marketplace/v1/web/stores/wholesales/info?id=${id}&key=${key}`
    );
  },
};

export default BuyerServices;

import React from "react";
import { IoMdCart } from "react-icons/io";
import { Link } from "react-router-dom";
import useSellerStore from "../../hooks/useSellerStore";
import { useCart } from "../../providers/CartProvider";
import ProfileDropDown from "./ProfileDropDown";
export default function Nav() {
  const store = useSellerStore();
  const { cart } = useCart();
  const cartLength = cart.reduce((arr, v) => arr + Number(v.quantity), 0);
  return (
    <nav className="sticky top-0 bg-white z-10 border-b border-[0.8px]">
      <div className="mx-auto  px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center gap-4">
            <img
              src={store?.logo}
              alt=""
              className="h-8 w-8 rounded-full object-contain"
            />
            <div className="flex-shrink-0 font-bold">
              <Link to={"/"}>{store?.name}</Link>
            </div>
          </div>
          <div className="hidde  flex items-center">
            <div className="">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link
                  to="/cart"
                  className=" px-3 py-2 text-sm font-medium flex gap-2 items-center"
                  aria-current="page"
                >
                  <IoMdCart />
                  {cartLength > 0 && (
                    <span className="absolute mb-4 ml-2 rounded-full bg-red-800 px-1 flex justify-center items-center w-auto h-3 text-[9px] text-white">
                      {cartLength}
                    </span>
                  )}
                  <span className="hidden md:block"> My Cart</span>
                </Link>
              </div>
            </div>
            <div className="ml-1 flex items-center md:ml-6">
              <ProfileDropDown />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

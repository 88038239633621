import { Listbox, Transition } from "@headlessui/react";
import { useMemo } from "react";
import { TiArrowUnsorted } from "react-icons/ti";

export default function SelectVariant({
  variant,
  setSelectedVariant,
  selectedVariant,
}) {
  const handleVariantChange = (_variant) => {
    // check if variant is already in selected list
    const checkVariant = selectedVariant.find(
      (item) => item.variant_id === variant.id
    );
    if (checkVariant) {
      const updatedVariant = selectedVariant.map((item) =>
        item.variant_id === _variant.variant_id ? _variant : item
      );
      setSelectedVariant(updatedVariant);
    } else {
      setSelectedVariant([...selectedVariant, _variant]);
    }
  };

  const active = useMemo(() => {
    return selectedVariant.find((item) => item.variant_id === variant.id);

    //eslint disable nextline
  }, [selectedVariant]);

  return (
    <div className="flex flex-col gap-1 mb-4">
      <label className="text-xs">{variant.name} </label>

      <Listbox onChange={(e) => handleVariantChange(JSON.parse(e))}>
        {({ open }) => (
          <div className=" relative">
            <Listbox.Button className="border h-9 w-fit relative cursor-default rounded bg-white pl-3 pr-10 text-left focus:outline-none sm:text-sm">
              <span className="block truncate">
                {active ? active?.entry : "Select"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <TiArrowUnsorted
                  className="h-3 w-3 text-gray-800"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              // as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 w-40 min-w-[40px]  h-auto  py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {variant.options?.map((entry) => (
                  <Listbox.Option
                    key={entry}
                    value={JSON.stringify({
                      entry: entry,
                      variant_id: variant.id,
                      variant_name: variant.name,
                    })}
                    className={({ active, selected }) =>
                      `
                          ${selected && "bg-gray-500 text-white"} 
                          cursor-default select-none relative py-2 px-4 pr-4 gap-2 hover:bg-gray-200 
                          ${active ? "bg-gray-900 text-white" : ""}`
                    }
                  >
                    {entry}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}

import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import BackButton from "../components/common/BackButton";
import InputArea from "../components/forms/InputArea";
import AuthServices from "../services/AuthServices";

export default function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleResetPassword = async (data) => {
    try {
      const res = await AuthServices.resetPassword(data);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="min-h-screen bg-white">
        <div className="mt-4 max-w-5xl mx-auto">
          <BackButton />
        </div>
        <div className="w-full flex flex-col items-center  py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-xl text-center font-medium text-gray-900">
                Change Password
              </h2>
            </div>

            <hr className="mt-6" />

            <div className="mt-8">
              <div className="mt-6">
                <form
                  onSubmit={handleSubmit(handleResetPassword)}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm text-gray-600"
                    >
                      Current Password
                    </label>
                    <div className="mt-1">
                      <InputArea
                        type="text"
                        name="username"
                        autoComplete="on"
                        register={register}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm text-gray-600"
                    >
                      New Password*
                    </label>
                    <div className="mt-1 relative">
                      <InputArea
                        type={showPassword ? "text" : "password"}
                        name="password"
                        register={register}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm text-gray-600"
                    >
                      Confirm New Password*
                    </label>
                    <div className="mt-1 relative">
                      <InputArea
                        type={showPassword ? "text" : "password"}
                        name="password"
                        register={register}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-0 left-auto right-4 focus:outline-none "
                      >
                        {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                      </button>
                    </div>
                  </div>

                  <hr className="mt-6" />

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                    >
                      Update Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

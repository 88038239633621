import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";
import { HiPhone } from "react-icons/hi";
import { MdLocationPin } from "react-icons/md";
import { RiPencilFill } from "react-icons/ri";
import AddressServices from "../../services/AddressServices";
import { notifySuccess } from "../../utils/toast";
import Button from "../common/Button";
import InputArea from "../forms/InputArea";
import RadioInput from "../forms/RadioInput";
import Select from "../forms/Select";
export default function AddressModal({
  open,
  setOpen,
  selectAddress,
  setSelectAddress,
  setDeliveryAddress,
  deliveryAddress,
  setDeliveryOption,
}) {
  const [loading, setLoading] = useState(true);
  const [addressList, setAddressList] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [countriesList, setCountriesList] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const country = watch("country_code");
  const regionCode = watch("region_code");
  const id = watch("id");

  const countries = countriesList?.map((item) => ({
    label: item.name,
    value: item.code,
    id: item.code,
  }));

  const regionsList = useMemo(() => {
    if (!country) return [];

    const countryList = countriesList.find((item) => item.code === country);

    if (!countryList) return [];

    setValue("region");
    setValue("local_area_code");
    const regions = countryList?.regions.map((item) => ({
      label: item.name,
      value: item.code,
      id: item.code,
    }));
    return regions;
  }, [country, countriesList]);

  const localAreaList = useMemo(() => {
    if (!country) return [];

    const countryList = countriesList.find((item) => item.code === country);
    if (!countryList) return [];

    const regionList = countryList?.regions?.find(
      (item) => item.code === regionCode
    );

    if (!regionList) return [];

    const localAreas = regionList?.local_areas?.map((item) => ({
      label: item.name,
      value: item.code,
      id: item.code,
    }));
    return localAreas;
  }, [regionCode, country, countriesList]);

  const submit = (data) => {
    try {
      if (data.id) {
        const res = AddressServices.update(data);
        setSelectAddress(true);
        notifySuccess(res.message);
        return;
      }
      const res = AddressServices.create(data);
      notifySuccess(res.message);
      setSelectAddress(true);
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelectAddress(false);
    setSelectedAddress(null);
    reset();
  };

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const res = await AddressServices.get();
        const countries = await AddressServices.getCountries();
        setAddressList(res?.data?.addresses);
        setCountriesList(countries?.data?.countries);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    if (selectAddress) {
      fetchAddress();
    }
  }, [selectAddress]);

  const handleEdit = (item) => {
    setValue("id", item?.id);
    setValue("first_name", item?.first_name);
    setValue("last_name", item?.last_name);
    setValue("phone", item?.phone);
    setValue("email", item?.email);
    setValue("alt_phone", item?.alt_phone);
    setValue("address", item?.address);
    setValue("city", item?.city);
    setValue("post_code", item?.post_code);
    setValue("landmark", item?.landmark);
    setValue("country_code", item?.country_code);
    setValue("region_code", item?.region_code);
    setValue("local_area_code", item?.local_area_code);
    setSelectAddress(false);
  };

  const handleSelectAddress = () => {
    setDeliveryAddress(selectedAddress);
    setDeliveryOption("");
    setOpen(false);
    setSelectAddress(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={closeModal}
      >
        <div
          className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
          style={{ fontSize: 0 }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:align-middle md:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-3xl">
              <div className="w-full relative flex items-center bg-white  overflow-hidden shadow-2xl">
                <button
                  type="button"
                  className="absolute top-2 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-4 md:right-6 lg:top-4 lg:right-8"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close</span>
                  <AiOutlineClose
                    // className="h-6 w-6 text-red-500"
                    aria-hidden="true"
                  />
                </button>

                {selectAddress ? (
                  <div className="w-full relative    overflow-hidden shadow-2xl">
                    <div className="py-3 border-b px-8">
                      <h1 className="font-medium">Delivery Address</h1>
                    </div>
                    {loading && <p className="py-4 px-4">loading...</p>}
                    <ul className="p-8 py-6 space-y-4">
                      {addressList?.map((item) => (
                        <li key={item.id} className="cursor-pointer">
                          <label
                            onClick={() => setSelectedAddress(item)}
                            htmlFor={item.id}
                            className=" border rounded p-4 py-4 flex gap-2 cursor-pointer w-full"
                          >
                            <div>
                              <RadioInput
                                name={"address"}
                                id={item.id}
                                // checked={item.id === deliveryAddress?.id}
                                value={item.id}
                              />
                            </div>
                            <div className="space-y-2 w-full">
                              <div className="flex items-center justify-between gap-2  w-full">
                                <span className="font-medium text-sm">
                                  {item.first_name} {item.last_name}
                                </span>
                                <button
                                  onClick={() => handleEdit(item)}
                                  type="button"
                                  className="text-xs text-blue-700 flex items-center gap-1"
                                >
                                  <RiPencilFill />
                                  Edit
                                </button>
                              </div>
                              <div className="flex items-center gap-2">
                                <MdLocationPin />
                                <span className="text-sm">{item.address}</span>
                              </div>
                              <div className="flex items-center gap-2">
                                <HiPhone />
                                <span className="text-sm">{item.phone}</span>
                              </div>
                            </div>
                          </label>
                        </li>
                      ))}

                      <li>
                        <div className="flex items-center gap-5 ">
                          <Button
                            type="button"
                            className={
                              "bg-white text-red-700 border w-full py-2 rounded border-red-700 text-sm"
                            }
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            onClick={handleSelectAddress}
                            className={
                              "bg-black  text-white  w-full py-2 rounded  text-sm font-medium"
                            }
                          >
                            Select Address
                          </Button>
                        </div>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className=" divide-x w-full grid grid-cols-gap-x-6 items-start sm:grid-cols-2">
                    <div
                      className=" font-medium text-gray-900 flex items-center gap-2  px-6 border-b  py-2"
                      onClick={() => setSelectAddress(true)}
                    >
                      {selectedAddress && (
                        <button className=" cursor-pointer">
                          <FiChevronLeft />
                        </button>
                      )}
                      {id ? "Edit Address" : "Add New Address"}
                    </div>

                    <form
                      onSubmit={handleSubmit(submit)}
                      className="col-span-2   lg:p-8 p-4 grid grid-cols-2 gap-4"
                    >
                      <div className="relative ">
                        <label className="block text-sm text-gray-600">
                          First Name
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="first_name"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>
                      <div className="relative ">
                        <label className="block text-sm text-gray-600">
                          Last Name
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="last_name"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>
                      <div className="relative ">
                        <label className="block text-sm text-gray-600">
                          Phone Number
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="phone"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>
                      <div className="relative ">
                        <label className="block text-sm text-gray-600">
                          Additional Phone Number
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="alt_phone"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>
                      <div className="relative col-span-2 ">
                        <label className="block text-sm text-gray-600">
                          Email (Optional)
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="email"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>
                      <div className="relative col-span-2 ">
                        <label className="block text-sm text-gray-600">
                          Delivery Address
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="address"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>
                      <div className="relative col-span-2 ">
                        <label className="block text-sm text-gray-600">
                          Nearest Landmark
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="landmark"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>
                      <div className="relative col-span-2 ">
                        <label className="block text-sm text-gray-600">
                          City
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="city"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>

                      <div className="relative ">
                        <label className="block text-sm text-gray-600">
                          State/Region
                        </label>
                        <div className="mt-1">
                          <Select
                            options={countries}
                            Controller={Controller}
                            control={control}
                            name="country_code"
                            unSelect={false}
                          />
                        </div>
                      </div>
                      <div className="relative">
                        <label className="block text-sm text-gray-600">
                          State/Region
                        </label>
                        <div className="mt-1">
                          <Select
                            options={regionsList}
                            Controller={Controller}
                            control={control}
                            name="region_code"
                            unSelect={false}
                          />
                        </div>
                      </div>
                      <div className="relative col-span-2">
                        <label className="block text-sm text-gray-600">
                          Local Area
                        </label>
                        <div className="mt-1">
                          <Select
                            options={localAreaList}
                            Controller={Controller}
                            control={control}
                            name="local_area_code"
                            unSelect={false}
                          />
                        </div>
                      </div>
                      <div className="relative col-span-2 ">
                        <label className="block text-sm text-gray-600">
                          Postal Code (Optional)
                        </label>
                        <div className="mt-1">
                          <InputArea
                            type="text"
                            name="post_code"
                            placeholder=""
                            autoComplete="off"
                            register={register}
                          />
                        </div>
                      </div>

                      <div className="flex items-center gap-2 col-span-2  ">
                        <Button
                          type="button"
                          className={
                            "bg-white text-red-700 border w-full py-2 rounded border-red-700 text-sm"
                          }
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className={
                            "bg-black  text-white  w-full py-2 rounded  text-sm font-medium"
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </form>
                    {/* <div className="bg-gray-400 h-full w-full"></div> */}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import PaystackPop from "@paystack/inline-js";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { HiOutlineExternalLink, HiPlusSm } from "react-icons/hi";
import { Link } from "react-router-dom";
import LocationImg from "../assets/images/delivery-location.png";
import DeliveryOptions from "../components/checkout/DeliveryOptions";
import PaymentOptions from "../components/checkout/PaymentOptions";
import InputArea from "../components/forms/InputArea";
import Label from "../components/forms/Label";
import ListSelect from "../components/forms/ListSelect";
import RadioInput from "../components/forms/RadioInput";
import Spinner from "../components/loadings/Spinner";
import AddressModal from "../components/modals/AddressModal";
import SuccessModal from "../components/modals/SuccessModal";
import Price from "../components/products/Price";
import useBuyerStore from "../hooks/useBuyerStore";
import useSellerStore from "../hooks/useSellerStore";
import { useCart } from "../providers/CartProvider";
import AddressServices from "../services/AddressServices";
import PaymentServices from "../services/PaymentServices";
import { notifyError, notifySuccess } from "../utils/toast";

export default function Checkout() {
  const { cart, setCart, total, totalVat, subtotal } = useCart();
  const [paymentOption, setPaymentOption] = useState("online");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("");
  const [openAddress, setOpenAddress] = useState(false);
  const [selectAddress, setSelectAddress] = useState(null);
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [outlets, setOutlets] = useState();
  const [deliveryOption, setDeliveryOption] = useState();
  const [pickupAddress, setPickupAddress] = useState();
  const [logisticsCoy, setLogisticsCoy] = useState();
  const [loading, setLoading] = useState(false);
  const distributor = useBuyerStore();
  const store = useSellerStore();

  const serializeOptions = (opt) => {
    return opt.map((o) => ({ variant: o.variant_name, option: o.entry }));
  };

  const calculateShippingCostPerItem = (item, logisticsCoy) => {
    const logisticsCost = JSON.parse(logisticsCoy);
    const itemCost =
      item.totalWeightKg <= 1
        ? logisticsCost.cost
        : logisticsCost.cost +
          logisticsCost.costPerMetric * (item.totalWeightKg - 1);

    return itemCost;
  };

  const calculateShippingCost = () => {
    if (!logisticsCoy || !cart) return 0;

    const cartSummary = cart.reduce((acc, item) => {
      if (item.requiredShipping) {
        const itemCost = calculateShippingCostPerItem(item, logisticsCoy);
        return acc + itemCost;
      }
      return acc;
    }, 0);
    // console.log({ cartSummary });
    return cartSummary;
  };

  const useOrderSubmit = async () => {
    if (paymentOption === "online" && !paymentGateway) {
      notifyError("Please select payment gateway");
      return;
    }

    let payload = {
      channel: "web",
      discount: 0,
      delivery_charge: 0.0,
      // extra_charge: 0.0,
      discount_code: store?.discount?.code,
      payment_option: paymentOption, //online //pod //credit
      delivery_option: deliveryOption, //pickup //delivery
      delivery_service: logisticsCoy ? JSON.parse(logisticsCoy) : null,
      delivery: deliveryAddress, //use pickup address if delivery_option is pickup
      pickup_address: pickupAddress ? JSON.parse(pickupAddress) : null,
      items: cart.map((item) => ({
        product_id: item.id,
        quantity: item.quantity,
        variants: item?.selectedComb
          ? [
              {
                combination_id: item.selectedComb.id,
                quantity: item.quantity,
                options: serializeOptions(item.variants),
              },
            ]
          : null,
      })),
    };

    try {
      setLoading(true);
      const res = await PaymentServices.createOrder(payload);
      if (res.data?.total === 0) {
        setShowSucessModal(true);
        return;
      }

      const init = await PaymentServices.initPayment({
        order_id: res.data.order_id,
        gateway: paymentGateway,
      });

      if (init.data.gateway === paymentGateway) {
        const paystack = new PaystackPop();
        await paystack.newTransaction({
          key: init.data.public_key,
          reference: init.data.reference,
          email: init.data.email,
          amount: init.data.amount,
          onSuccess: async (transaction) => {
            verifyTransactionOnBackend(transaction.reference);
          },
          onCancel: async () => {
            verifyTransactionOnBackend(init.data.reference);
            notifyError("Payment cancelled");
          },
        });
      }

      if (payload.payment_gateway === "flutterwave") {
        window.FlutterwaveCheckout({
          public_key: init.data.public_key,
          tx_ref: init.data.reference,
          amount: init.data.amount,
          currency: "NGN",
          payment_options: "card, banktransfer, ussd",
          customer: {
            email: init.data.email,
          },
          customizations: {
            title: "Store Bounty",
            description: init.data.description,
            logo: "https://ibunchng-app-assets.s3.eu-west-1.amazonaws.com/storebounty-logo.png",
          },
          callback: async function (payment) {
            console.log({ reference: init.data.reference, payment });
            verifyTransactionOnBackend(payment.tx_ref);
          },
          onclose: function (incomplete) {
            if (incomplete || window.verified === false) {
              verifyTransactionOnBackend(init.data.reference);
              // document.querySelector("#payment-failed").style.display = "block";
            } else {
              // document.querySelector("form").style.display = "none";
              // if (window.verified == true) {
              //   document.querySelector("#payment-success").style.display =
              //     "block";
              // } else {
              //   document.querySelector("#payment-pending").style.display =
              //     "block";
              // }
            }
          },
        });
      }
    } catch (err) {
      const message =
        err.response?.data?.message || err.message || err.toString();
      notifyError(message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const verifyTransactionOnBackend = async (transaction, selectedTheme) => {
    const status = await PaymentServices.confirmPayment({
      reference: transaction,
    });
    if (status.data.status === "SUCCESS") {
      notifySuccess("Paymentsuccessful");
      setShowSucessModal(true);
      setCart([]);
    } else {
      notifyError("Unable to process payment");
    }
  };

  const handlePaymentOptionChange = (e) => {
    setPaymentGateway("");
    setPaymentOption(e.target.value);
  };

  useEffect(() => {
    const fetchOutlets = async () => {
      try {
        const res = await AddressServices.getBuyerOutlets();

        const outlets = res.data?.outlets?.map((item) => ({
          name: item.name,
          id: item.id,
          label: item.name,
        }));
        setOutlets(outlets);
      } catch (err) {
        console.log(err);
      }
    };

    fetchOutlets();
  }, []);

  const calculateDiscount = () => {
    const discount = store?.discount;
    const {
      target,
      type,
      value,
      minimum_purchase_vol,
      minimum_purchase_qty,
      is_running,
    } = discount ?? {};
    const cartQty = cart.reduce((total, item) => total + item.quantity, 0);

    if (!target || !type || !is_running) {
      return 0;
    }

    const meetsVolThreshold =
      target === "VOL" && subtotal >= minimum_purchase_vol;
    const meetsQtyThreshold =
      target === "QTY" && cartQty >= minimum_purchase_qty;
    const isValidDiscount = meetsVolThreshold || meetsQtyThreshold;

    if (!isValidDiscount) {
      return 0;
    }

    const discountAmount = type === "AMOUNT" ? value : (value / 100) * subtotal;

    return discountAmount;
  };

  const sumTotal = total - calculateDiscount() + calculateShippingCost();
  const currency_code = store?.currency_code;

  return (
    <>
      <AddressModal
        open={openAddress}
        setOpen={setOpenAddress}
        selectAddress={selectAddress}
        setSelectAddress={setSelectAddress}
        setDeliveryAddress={setDeliveryAddress}
        deliveryAddress={deliveryAddress}
        setDeliveryOption={setDeliveryOption}
      />
      <SuccessModal open={showSucessModal} />
      <div className="bg-white min-h-screen pb-32 px-4 lg:px-0">
        <div className="max-w-6xl mx-auto pt-6">
          <h1 className="text-2xl font-bold">Delivery and Payment Details</h1>

          <div className="border grid lg:grid-cols-7 mt-6 divide-x">
            <div className="lg:col-span-4 p-6 space-y-6">
              {distributor?.type === "internal" && (
                <div className="flex flex-col gap-2 ">
                  <div className="flex items-center gap-2">
                    <div className="flex justify-between items-center w-full">
                      <Label className={"font-medium"}>
                        Outlet{" "}
                        <span className="text-xs text-gray-500">
                          (Delivery address)
                        </span>
                      </Label>
                      <Link
                        to={"https://admin.storebounty.com/"}
                        className="flex gap-2 items-center text-xs text-blue-800"
                      >
                        Add New <HiOutlineExternalLink />
                      </Link>
                    </div>
                  </div>
                  <div className="">
                    <ListSelect
                      options={outlets}
                      selected={deliveryAddress}
                      setSelected={setDeliveryAddress}
                    />

                    <FiChevronDown className="absolute top-2 right-3" />
                  </div>
                </div>
              )}

              {distributor?.type === "external" && (
                <>
                  <div className="flex flex-col gap-2 ">
                    <div className="flex items-center gap-2">
                      <RadioInput />
                      <Label className={"font-medium"}>Delivery Addresss</Label>
                    </div>
                    <div
                      className="ml-7 cursor-pointer relative"
                      onClick={() => {
                        setSelectAddress(true);
                        setOpenAddress(true);
                      }}
                    >
                      <InputArea value={deliveryAddress?.address} readOnly />
                      <FiChevronDown className="absolute top-3 right-3" />
                    </div>
                  </div>
                  <div className="ml-8">
                    <button
                      onClick={() => setOpenAddress(true)}
                      className="flex gap-1 items-center px-2 py-1 text-xs font-medium text-blue-800 border border-blue-800 rounded"
                    >
                      <HiPlusSm /> Add New
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className=" col-span-3 hidden lg:block">
              <img
                src={LocationImg}
                alt=""
                className="w-full h-40 object-contain"
              />
            </div>
          </div>

          <DeliveryOptions
            store={store}
            deliveryOption={deliveryOption}
            setDeliveryOption={setDeliveryOption}
            pickupAddress={pickupAddress}
            setPickupAddress={setPickupAddress}
            logisticsCoy={logisticsCoy}
            setLogisticsCoy={setLogisticsCoy}
            deliveryAddress={deliveryAddress}
            calculateShippingCost={calculateShippingCost}
          />

          <PaymentOptions
            handlePaymentOptionChange={handlePaymentOptionChange}
            paymentOption={paymentOption}
            store={store}
            paymentGateway={paymentGateway}
            setPaymentGateway={setPaymentGateway}
            distributor={distributor}
          />

          <div className="p-7 border mt-6 space-y-3">
            <h1>Order Summary</h1>
            <div className="flex justify-between">
              <h6 className="text-sm">Order Total</h6>
              <p className="text-sm">
                {getSymbolFromCurrency(currency_code)}
                <Price>{subtotal}</Price>
              </p>
            </div>
            <div className="flex justify-between">
              <h6 className="text-sm">Delivery Fee</h6>
              <p className="text-sm">
                {getSymbolFromCurrency(currency_code)}
                <Price>{calculateShippingCost()}</Price>
              </p>
            </div>
            <div className="flex justify-between">
              <h6 className="text-sm">Taxes</h6>
              <p className="text-sm">
                {getSymbolFromCurrency(currency_code)}
                <Price>{totalVat}</Price>
              </p>
            </div>
            <div className="flex justify-between">
              <h6 className="text-sm">Discount</h6>
              <p className="text-sm">
                {getSymbolFromCurrency(currency_code)}
                <Price>{calculateDiscount()}</Price>
              </p>
            </div>
            <hr />
            <div className="flex justify-between">
              <h6 className="text-sm font-medium">Total to Pay</h6>
              <p className="text-sm font-medium">
                {getSymbolFromCurrency(currency_code)}
                <Price>{sumTotal}</Price>
              </p>
            </div>
          </div>

          <div className="mt-6" onClick={useOrderSubmit}>
            {/* <Link to={"/orders"}> */}
            <button className="bg-black text-sm w-full text-white py-3 flex gap-2 items-center justify-center">
              Proceed to payment {loading && <Spinner />}
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { FiSearch } from "react-icons/fi";

export default function SearchBar({ search, setSearch }) {
  return (
    <div className="pt-4 px-6 lg:px-8 flex gap-4 items-center flex-wrap">
      <div className="flex-1 relative ">
        <input
          type={"search"}
          className="h-12 w-full lg:px-12 px-4 focus:outline"
          placeholder="Search product"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <FiSearch className="hidden lg:block absolute inset-0 top-4 left-6 text-gray-500" />
      </div>
    </div>
  );
}

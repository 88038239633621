import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import Label from "../forms/Label";
import RadioInput from "../forms/RadioInput";

export default function PaymentOptions({
  handlePaymentOptionChange,
  paymentOption,
  store,
  paymentGateway,
  setPaymentGateway,
  distributor,
}) {
  return (
    <div className="p-7 border mt-6">
      <h1>Payment Options</h1>
      <div className="flex items-center gap-2 mt-6">
        <RadioInput
          name="payment_option"
          value={"online"}
          onChange={handlePaymentOptionChange}
          checked={paymentOption === "online"}
        />
        <Label>Online Payments</Label>
      </div>
      {paymentOption === "online" && (
        <div className="grid grid-cols-2 gap-5 mt-3 ml-8">
          {store?.payment_gateways?.map((item) => (
            <div
              key={item.id}
              className="border px-5 py-4 rounded flex items-center justify-center relative"
            >
              <RadioInput
                className={"absolute top-2 left-3"}
                name="gateway"
                value={item.name}
                onChange={() => setPaymentGateway(item.name)}
                checked={paymentGateway === item.name}
              />
              <img src={item.logo} alt="" className="w-8/12 " />
            </div>
          ))}
        </div>
      )}

      {distributor?.allow_pod && (
        <div className="flex items-center gap-2 mt-6">
          <RadioInput
            name="payment_option"
            value={"pod"}
            onChange={handlePaymentOptionChange}
            checked={paymentOption === "pod"}
          />
          <Label>Pay on Delivery</Label>
        </div>
      )}

      <div className="flex items-center gap-2 mt-4">
        <RadioInput
          name="payment_option"
          value={"credit"}
          onChange={handlePaymentOptionChange}
          checked={paymentOption === "credit"}
        />
        <Label>
          Credit Purchase{" "}
          <small className="text-gray-600">
            (
            {`Up to the tune of ${getSymbolFromCurrency(store?.currency_code)}${
              distributor?.max_credit
            } for a period of ${distributor?.credit_period} days`}
            )
          </small>
        </Label>
      </div>
    </div>
  );
}

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSellerStore from "../hooks/useSellerStore";
import StorageServices from "../services/storageServices";
import { notifyError, notifySuccess } from "../utils/toast";
// import { notifyError } from "../utils/toast";
// import useActiveStore from "./useActiveStore";

export const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export default function CartProvider({ children }) {
  const [cart, setCart] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [totalVat, setTotalVat] = React.useState(0);
  const [subtotal, setSubtotal] = React.useState(0);
  const [variant, setVariant] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isInitiallyFetched, setIsInitiallyFetched] = useState(false);
  const toggleCart = () => setIsOpen(!isOpen);
  const activeStore = useSellerStore();

  const addToCart = (product, qty, selectedVariant) => {
    if (!product) return;

    //for product that have variants
    if (selectedVariant?.length > 0) {
      const variant = getCombinationName(selectedVariant);
      const comb = findCombinations(product, variant);

      if (!comb || !comb?.active) {
        notifyError("Product Variants selected is not available");
        return;
      }

      if (comb.track_quantity && qty > comb.quantity) {
        notifyError("Selected Quantity not available");
        return;
      }

      const _product = cart.find(
        (item) => item.selectedComb?.name === comb.name
      );

      if (_product) {
        return upDateExisting(_product, qty);
      }

      return addNewProduct(product, qty, comb, selectedVariant);
    } else {
      //else the product has no variant
      const _product = cart.find((item) => item.id === product.id);

      if (_product) {
        return upDateExisting(_product);
      }
      return addNewProduct(product, qty);
    }
  };

  const addNewProduct = (product, qty, combination, selectedVariant) => {
    if (!product) {
      return;
    }

    // Determine the product price based on whether it's on sale or not.
    const productPrice = product.pricing.on_sales
      ? product.pricing.sales_price
      : product.pricing.current_price;

    // Determine the combination price based on whether it's on sale or not.
    const combinationPrice = combination?.on_sales
      ? combination?.sales_price
      : combination?.current_price;

    const newProduct = {
      id: product.id,
      cart_Id: (Math.random() + 1).toString(36).substring(7),
      title: product.title,
      image: product.image,
      price: productPrice,
      pricing: product.pricing,
      exempt_vat: product.exempt_vat,
      quantity: qty || 1,
      variants: selectedVariant,
      selectedComb: combination
        ? { ...combination, price: combinationPrice }
        : null,
      track_quantity: product.inventory.track_quantity,
    };

    if (product.shipping_required) {
      newProduct.requiredShipping = product.shipping_required;
      const itemWeightKg =
        product.weightUnit === "gram" ? product.weight / 1000 : product.weight;
      const totalWeightKg = itemWeightKg * product.qty;
      newProduct.totalWeightKg = totalWeightKg;
    }

    // Add the new product to the cart.
    setCart([...cart, newProduct]);
    notifySuccess("Product added to cart successfully");
  };

  const upDateExisting = (product, qty) => {
    if (!product) return;
    setCart((prev) =>
      prev.map((item) =>
        item.cart_Id === product.cart_Id
          ? {
              ...item,
              quantity: qty ? item.quantity + qty : +item.quantity + 1,
            }
          : item
      )
    );
    notifySuccess("Product updated successfully");
  };

  const removeFromCart = (product) => {
    if (!product) return;

    setCart(cart.filter((item) => item.cart_Id !== product.cart_Id));
    setTotal(total - product.pricing.current_price);
  };

  const increaseQuantity = (product) => {
    if (!product) return;

    setCart(
      cart.map((item) => {
        if (item.cart_Id === product.cart_Id) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      })
    );
  };

  const decreaseQuantity = (product) => {
    if (!product) return;

    if (product.quantity === 1) {
      return;
    }
    setCart(
      cart.map((item) => {
        if (item.cart_Id === product.cart_Id) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      })
    );
  };

  const adjustQuantity = (product, quantity) => {
    if (!product) return;

    if (quantity < 1) {
      return;
    }

    setCart(
      cart.map((item) => {
        if (item.cart_Id === product.cart_Id) {
          return { ...item, quantity: quantity };
        }
        return item;
      })
    );
  };

  useMemo(() => {
    if (isInitiallyFetched) {
      StorageServices.set("Distributors-cart", JSON.stringify(cart));
    }

    const total = () => {
      return cart.map((item) => {
        return {
          exempt_vat: item.exempt_vat,
          quantity: item.quantity,
          price: item.variants // check for variants and onsale
            ? item.selectedComb.price
            : item.price,
        };
      });
    };

    const subtotal = total().reduce((a, b) => {
      return a + b.price * b.quantity;
    }, 0);

    setSubtotal(subtotal);
    setTotal(subtotal);

    //calculate vat
    if (activeStore?.apply_vat) {
      const vat = total().reduce((acc, item) => {
        if (!item.exempt_vat) {
          return (
            acc + (item.price * item.quantity * activeStore.vat_percent) / 100
          );
        }
        return acc;
      }, 0);
      setTotalVat(vat);
      setTotal((prev) => prev + vat);
    }
  }, [cart, isInitiallyFetched]);

  useEffect(() => {
    const prev = StorageServices.get("Distributors-cart");
    if (prev) {
      setCart(JSON.parse(prev));
    }
    setIsInitiallyFetched(true);
  }, []);

  const value = {
    cart,
    total,
    addToCart,
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
    variant,
    setVariant,
    isOpen,
    setIsOpen,
    toggleCart,
    setCart,
    totalVat,
    subtotal,
    adjustQuantity,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

const getCombinationName = (variants) => {
  if (!variants) return;
  return variants
    .map((v) => v.entry)
    .sort()
    .join("/");
};

const findCombinations = (product, combination) => {
  if (!product || !combination) {
    return;
  }

  const comb = product.variant_combinations?.find(
    (c) => c.name === combination
  );

  return comb;
};

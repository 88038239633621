import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layout/Layout";
import NotFound from "../pages/404";
import Account from "../pages/Account";
import ResetPassword from "../pages/auth/ChangePassword";
import ConfirmOTP from "../pages/auth/ConfirmOTP";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import ResetSuccess from "../pages/auth/resetSuccess";
import Cart from "../pages/Cart";
import ChangePassword from "../pages/ChangePassword";
import Checkout from "../pages/Checkout";
import DetailPage from "../pages/DetailPage";
import Home from "../pages/Home";
import OrderDetails from "../pages/OrderDetails";
import Orders from "../pages/Orders";
import UnAuthorized from "../pages/unAuthorized";

const mainPath = "/:id/:key";
const router = createBrowserRouter([
  {
    path: `${mainPath}/login`,
    element: <Login />,
  },
  {
    path: `/forgot-password`,
    element: <ForgotPassword />,
  },
  {
    path: `/confirm-otp`,
    element: <ConfirmOTP />,
  },
  {
    path: `/reset-password`,
    element: <ResetPassword />,
  },
  {
    path: `/reset-success`,
    element: <ResetSuccess />,
  },
  {
    path: `/authorized`,
    element: <UnAuthorized />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: `/`,
        element: <Home />,
      },
      {
        path: `/details/:id`,
        element: <DetailPage />,
      },
      {
        path: `/cart`,
        element: <Cart />,
      },
      {
        path: `/orders`,
        element: <Orders />,
      },
      {
        path: `/orders/:id`,
        element: <OrderDetails />,
      },
      {
        path: `/checkout`,
        element: <Checkout />,
      },
      {
        path: `/account`,
        element: <Account />,
      },
      {
        path: `/change-password`,
        element: <ChangePassword />,
      },
      {
        path: `*`,
        element: <NotFound />,
      },
    ],
  },
]);

export default router;

import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Badge from "../components/common/Badge";
import PackageIcon from "../components/icons/PackageIcon";
import OrderLoaing from "../components/loadings/OrderLoaing";
import Price from "../components/products/Price";
import useBuyerStore from "../hooks/useBuyerStore";
import OrderServices from "../services/OrderServices";

export default function Orders() {
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(true);
  const distributor = useBuyerStore();
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await OrderServices.get();
        console.log(res.data?.content);
        setOrders(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="bg-white min-h-screen">
      {orders?.content?.length > 0 ? (
        <main className="max-w-7xl mx-auto lg:px-8 px-4">
          <section aria-labelledby="recent-heading" className="lg:mt-16 mt-8">
            <h2 className="text-2xl font-medium">Recent orders</h2>

            <div className="space-y-20">
              <div>
                <h3 className="sr-only">Order placed on </h3>

                <table className="mt-4 w-full text-gray-900 sm:mt-6 border hidden lg:block">
                  <caption className="sr-only">Products</caption>
                  <thead className="sr-only bg-[#E0E0E0] text-sm text-black text-left sm:not-sr-only px-6">
                    <tr>
                      <th
                        scope="col"
                        className="sm:w-1/6 lg:w-1/6 pr-8 py-3 font-normal pl-6"
                      >
                        Order ID
                      </th>
                      <th
                        scope="col"
                        className="hidden w-2/6 pr-8 py-3 font-normal sm:table-cell"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="hidden w-1/6 pr-8 py-3 font-normal sm:table-cell"
                      >
                        Total
                      </th>
                      <th
                        scope="col"
                        className="hidden w-1/6 pr-8 py-3 font-normal sm:table-cell"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="w-1/6 py-3 font-normal text-left pr-6"
                      >
                        Order status
                      </th>
                      <th
                        scope="col"
                        className="w-1/6 py-3 font-normal text-left pr-6"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-b border-gray-200 divide-y divide-gray-200 text-sm sm:border-t">
                    {orders?.content.map((order) => (
                      <tr key={order.id}>
                        <td className="py-3 pl-6">
                          <span className="whitespace-nowrap">{order.id}</span>
                        </td>
                        <td className="hidden py-3 pr-8 sm:table-cell ">
                          <span>{order.order_desc}</span>
                        </td>
                        <td className="hidden py-3 pr-8 sm:table-cell ">
                          <div className="r">
                            {getSymbolFromCurrency(order.currency_code)}
                            <Price>{order.total}</Price>
                          </div>
                        </td>

                        <td className="hidden py-3 pr-8 sm:table-cell">
                          <span className="whitespace-nowrap">
                            {dayjs(order.createdAt).format(
                              "MMM D, YYYY h:mm A"
                            )}
                          </span>
                        </td>
                        <td className="hidden py-3 pr-8 sm:table-cell">
                          <Badge status={order.status} />
                        </td>
                        <td className="py-3 font-medium text-right whitespace-nowrap pr-6">
                          <Link to={`/orders/${order.id}`}>
                            <button className="bg-black text-sm py-2 px-4 rounded-full text-white">
                              View details
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="lg:hidden mt-4 space-y-4">
                  {orders?.content.map((order) => (
                    <div className="border border-gray-200 divide-y rounded-lg ">
                      <div className="flex items-center justify-between mx-6 py-2">
                        <p className="text-[10px]">Order ID</p>
                        <p className="text-sm">
                          <span className="whitespace-nowrap">{order.id}</span>
                        </p>
                      </div>
                      <div className="flex items-center justify-between mx-6 py-2">
                        <p className="text-[10px]">Description</p>
                        <p className="text-sm">
                          <span>{order.order_desc}</span>
                        </p>
                      </div>
                      <div className="flex items-center justify-between mx-6 py-2">
                        <p className="text-[10px]">Total</p>
                        <p className="text-sm">
                          {getSymbolFromCurrency(order.currency_code)}
                          <Price>{order.total}</Price>
                        </p>
                      </div>
                      <div className="flex items-center justify-between mx-6 py-2">
                        <p className="text-[10px]">Date and Time</p>
                        <p className="text-sm">
                          {dayjs(order.createdAt).format("MMM D, YYYY h:mm A")}
                        </p>
                      </div>
                      <div className="flex items-center justify-between mx-6 py-2">
                        <p className="text-[10px]">Order status</p>
                        <p className="text-sm">
                          <Badge status={order.status} />
                        </p>
                      </div>
                      <div className="flex items-center justify-between mx-6 py-2">
                        <p className="text-[10px]">Action</p>
                        <p className="text-sm">
                          <Link to={`/orders/${order.id}`}>
                            <button className="bg-black text-sm py-2 px-4 rounded-full text-white">
                              View details
                            </button>
                          </Link>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          {distributor.type === "external" && (
            <div className="border border-black flex items-center justify-between px-6 py-4 mt-6">
              <p className="text-sm">
                To show more orders you have previously made, Signup on Store
                Bounty
              </p>
              <a
                href="https://storebounty.com"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  type="submit"
                  className=" bg-black border border-transparent shadow-sm py-1 px-6 rounded text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                >
                  Sign Up
                </button>
              </a>
            </div>
          )}
        </main>
      ) : (
        <div className=" min-h-screen bg-white">
          <div className="max-w-6xl mx-auto py-6">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 ">
              Orders
            </h1>
            {loading ? (
              <OrderLoaing />
            ) : (
              <div className="mt-6 py-20 bg-[#F9F9F9] ">
                <div className="max-w-sm mx-auto flex flex-col justify-center items-center">
                  <PackageIcon className="w-28 h-28 text-[#DCE4EF]" />
                  <p className="font-medium mt-4">
                    Your Order History is Empty!
                  </p>
                  <p className="text-center py-4 text-sm lg:px-12">
                    Click the button below to start shopping and place your
                    first order.
                  </p>
                  <Link to={"/"}>
                    <button
                      type="submit"
                      className="rounded bg-black border border-transparent shadow-sm py-2 px-8 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                    >
                      Start Shopping
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import useSellerStore from "../../hooks/useSellerStore";

export default function Footer() {
  const store = useSellerStore();
  return (
    <>
      <footer class="bg-black border-b border-gray-800">
        <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div class="md:flex ">
            <div class="mb-3 md:mb-0 md:w-1/3">
              <div className="flex flex-col items-start gap-4">
                <img
                  src={store?.logo}
                  alt=""
                  className="h-8 w-8 rounded-full object-contain"
                />
                <div className="flex-shrink-0 font-bold text-white">
                  <Link to={"/"}>{store?.name}</Link>
                </div>
              </div>
            </div>

            <div class="md:w-2/3 grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2 class="mb-3 text-sm font-medium uppercase text-white">
                  Address
                </h2>
                <ul class="text-white text-xs">
                  <li class="mb-2">
                    <span>{store?.address}</span>
                  </li>
                </ul>
              </div>
              <div>
                <h2 class="mb-3 text-sm font-medium uppercase text-white">
                  Contact Us
                </h2>
                <ul class="text-white text-xs">
                  <li class="mb-2">
                    <a
                      href={`mailto:${store?.contact_email}`}
                      class="hover:underline "
                    >
                      {store?.contact_email}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`tel:${store?.contact_phone}`}
                      class="hover:underline"
                    >
                      {store?.contact_phone}
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 class="mb-3 text-sm font-medium uppercase text-white">
                  My Account
                </h2>
                <ul class="text-white text-xs">
                  <li class="mb-2">
                    <Link to={"/account"} class="hover:underline">
                      My Account
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to={"/cart"} class="hover:underline">
                      View Cart
                    </Link>
                  </li>
                  <li>
                    <Link to={"/orders"} class="hover:underline">
                      Order History
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="sticky bottom-0 bg-black text-white text-center py-2 text-sm">
        <span>
          {dayjs().format("YYYY")} {store?.name}. Store powered by{" "}
          <a
            href="https://storebounty.com"
            target="_blank"
            rel="noreferrer"
            className="hover:underline"
          >
            Store Bounty
          </a>
        </span>
        {/* <button>Show details</button> */}
      </div>
    </>
  );
}

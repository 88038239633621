import { useEffect, useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { Link, Navigate, useParams } from "react-router-dom";
import InputArea from "../../components/forms/InputArea";
import useLoginSubmit from "../../hooks/useLoginSubmit";
import { useAdmin } from "../../providers/AuthProvider";
import AddressServices from "../../services/AddressServices";
import BuyerServices from "../../services/BuyerServices";
import ImageServices from "../../services/ImageServices";
import StorageServices from "../../services/storageServices";

export default function Login() {
  const { id, key } = useParams();
  const [store, setStore] = useState({});
  const { user } = useAdmin();
  const [showPassword, setShowPassword] = useState(false);
  const [buyerStore, setBuyerStore] = useState({});

  const { register, handleSubmit, handleLoginSubmit } = useLoginSubmit();

  useEffect(() => {
    const fetchStoreInfo = async () => {
      try {
        const res = await BuyerServices.get(id, key);
        const sellerStore = res.data.store;
        StorageServices.set(
          "active-sellers-store",
          JSON.stringify(sellerStore)
        );

        StorageServices.set(
          "active-buyer-store",
          JSON.stringify(res.data?.distributor)
        );

        setBuyerStore(res.data?.distributor);

        StorageServices.set("active-stores-object", JSON.stringify(res.data));
        StorageServices.set(
          "active-buyer-store-keys",
          JSON.stringify({
            id,
            key,
          })
        );
        setStore(sellerStore);

        // Fetch Store Logo
        const entity = "logo";
        const logo = await ImageServices.getImage(sellerStore.id, entity);
        const logoUrl = logo?.data?.content?.[0]?.url;
        StorageServices.set(
          "active-sellers-store",
          JSON.stringify({ ...sellerStore, logo: logoUrl })
        );
        setStore({ ...sellerStore, logo: logoUrl });

        const countries = await AddressServices.getCountries();
        StorageServices.set(
          "supported-countries",
          JSON.stringify(countries.data?.countries)
        );
      } catch (err) {
        console.log(err);
      }
    };

    if (key && id) {
      fetchStoreInfo();
    }
  }, [id, key]);

  if (!key || !id) {
    return <Navigate to={"/authorized"} />;
  }

  if (user) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <div className="min-h-screen flex divide-x">
        <div className="hidden relative  flex-1 w-1/2 lg:flex flex-col justify-center items-center">
          <div className="rounded-full bg-gray-50 h-24 w-24">
            <img src={store?.logo} alt="" className="w-full" />
          </div>
          <h1 className="font-medium text-2xl mt-3">{store?.name}</h1>

          <div className="absolute bottom-0 mb-10">
            <p className="text-center text-sm">Powered by</p>
            <img src="/logo.svg" alt="" className="w-40" />
          </div>
        </div>
        <div className="w-1/2 flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="absolute right-0 left-0 top-0 mb-10 lg:hidden">
            <div className="flex items-center gap-2 px-8 py-2 border border-b">
              <img src={store?.logo} alt="" className="w-8 h-8 rounded-full" />
              <h1 className="font-medium text-xl">{store?.name}</h1>
            </div>
          </div>

          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl text-center font-medium text-gray-900">
                Login
              </h2>
              <div className="w-full">
                <p className="text-sm text-center mt-4">
                  <span className="text-yellow-800">{buyerStore?.name} </span>{" "}
                  Staff Only!
                </p>
              </div>
            </div>

            <hr className="mt-4" />

            <div className="mt-8">
              <div className="mt-6">
                <form
                  onSubmit={handleSubmit(handleLoginSubmit)}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm text-gray-600"
                    >
                      Username*
                    </label>
                    <div className="mt-1">
                      <InputArea
                        type="text"
                        name="username"
                        autoComplete="on"
                        register={register}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm text-gray-600"
                    >
                      Password*
                    </label>
                    <div className="mt-1 relative">
                      <InputArea
                        type={showPassword ? "text" : "password"}
                        name="password"
                        register={register}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-0 left-auto right-4 focus:outline-none "
                      >
                        {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                      </button>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        register={register}
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    {buyerStore?.type === "external" && (
                      <div className="text-sm">
                        <Link
                          to="/forgot-password"
                          className="italic text-blue-800 hover:text-blue-800"
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    )}
                  </div>

                  <hr className="mt-6" />

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="absolute right-0 left-0 bottom-0 mb-10 lg:hidden">
            <div className="flex flex-col items-center justify-center">
              <p className="text-center text-sm">Powered by</p>
              <img src="/logo.svg" alt="" className="w-40 text-center" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function Button({
  type,
  onClick,
  children,
  className,
  disabled,
  ...restProps
}) {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={className}
        disabled={disabled}
        {...restProps}
      >
        <span>{children}</span>
      </button>
    </>
  );
}

import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../components/common/BackButton";
import InputArea from "../../components/forms/InputArea";
import AuthLayout from "../../components/layout/AuthLayout";
import useActiveStoreKey from "../../hooks/useActiveStoreKey";
import useLoginSubmit from "../../hooks/useLoginSubmit";
import { useAdmin } from "../../providers/AuthProvider";

export default function ResetPassword() {
  const { user } = useAdmin();
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, onResetPassword, setValue } =
    useLoginSubmit();
  const storeKeys = useActiveStoreKey();

  useEffect(() => {
    if (location.state) {
      setValue("email", location.state.email);
      setValue("otp", location.state.otp);
    } else {
      navigate(`/${storeKeys.id}/${storeKeys.key}/login`);
    }
  }, []);

  if (user) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <AuthLayout>
        <div className="w-1/2 flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <BackButton />

          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-2xl text-center font-medium text-gray-900">
                Forgot Password
              </h2>
            </div>

            <hr className="mt-6" />

            <div className="mt-8">
              <p className="text-sm">
                create a new password. We strongly advise you to store it
                safely.
              </p>
              <div className="mt-6">
                <form
                  onSubmit={handleSubmit(onResetPassword)}
                  className="space-y-6"
                >
                  <div>
                    <label htmlFor="email" className="block text-sm ">
                      Enter password*
                    </label>
                    <div className="mt-1">
                      <InputArea
                        type="password"
                        name="new_password"
                        autoComplete="on"
                        register={register}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="email" className="block text-sm ">
                      Confirm password*
                    </label>
                    <div className="mt-1">
                      <InputArea
                        type="password"
                        name="repeat_password"
                        autoComplete="on"
                        register={register}
                      />
                    </div>
                  </div>

                  <hr className="mt-6" />

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

import classNames from "classnames";
import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import ReactPaginate from "react-paginate";
export default function Pagination({
  onChange,
  page,
  totalPages,
  currentPage,
  isFirstPage,
  isLastPage,
}) {
  return (
    <div>
      <ReactPaginate
        className="flex gap-2 items-center"
        containerClassName="flex gap-2 items-center"
        pageLinkClassName={classNames({
          "border text-sm cursor-pointer w-6 h-6 flex justify-center items-center block rounded text-gray-500": true,
        })}
        activeLinkClassName="border border-zinc-700 text-sm  w-5 h-5 block rounded text-gray-900"
        breakLabel="..."
        nextLabel={
          <div
            className={`${
              isLastPage
                ? " border  bg-zinc-500  text-white text-sm p-1 rounded  w-6 h-6"
                : " border  text-sm  p-1  rounded  w-6 h-6"
            } flex justify-center items-center`}
          >
            <BiChevronRight className="w-6 h-6" />
          </div>
        }
        hrefAllControls
        onPageChange={onChange}
        pageRangeDisplayed={page}
        pageCount={totalPages}
        previousLabel={
          <div
            // disabled={isFirstPage}
            className={`${
              isFirstPage
                ? " border  bg-zinc-500  text-white text-sm p-1 rounded  w-6 h-6"
                : " border  text-sm  p-1  rounded  w-6 h-6"
            } flex justify-center items-center`}
          >
            <BiChevronLeft className="w-6 h-6" />
          </div>
        }
        // renderOnZeroPageCount={null}
      />
    </div>
  );
}

import React from "react";

export default function WalletIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="4" width="16" height="16" rx="4" fill="#0D2770" />
      <rect x="5" y="10" width="4" height="4" rx="2" fill="#CED9E8" />
      <rect
        x="14"
        y="9"
        width="7"
        height="6"
        rx="3"
        fill="#CED9E8"
        stroke="white"
      />
    </svg>
  );
}

import requests from "./httpsServices";
import StorageServices from "./storageServices";

const PaymentServices = {
  initPayment(payload) {
    const storeId = this.getSellerStoreId();
    return requests.post(
      `/marketplace/v1/b2b/${storeId}/payments/initialize`,
      payload
    );
  },

  createOrder(payload) {
    const storeId = this.getSellerStoreId();
    return requests.post(
      `/marketplace/v1/b2b/${storeId}/orders/create`,
      payload
    );
  },

  confirmPayment(payload) {
    const storeId = this.getSellerStoreId();
    return requests.post(
      `/marketplace/v1/b2b/${storeId}/payment/status`,
      payload
    );
  },

  getSellerStoreId() {
    const sellerStore = StorageServices.get("active-sellers-store");
    if (sellerStore) {
      return JSON.parse(sellerStore)?.id;
    }
  },
};

export default PaymentServices;

import React from "react";

export default function DetailLoading() {
  return (
    <div className=" max-w-7xl mx-auto py-6 px-4 lg:px-0 animate-pulse">
      <h2 className="font-bold text-xl pb-4">Product Details</h2>
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            {/* Image gallery */}
            <div
              className={` object-center h-80 w-80 mx-auto object-contain bg-gray-200 `}
            ></div>

            {/* Product info */}
            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0 w-10/12">
              <div className="h-5 bg-gray-200 rounded"></div>

              <div className="mt-3">
                <div className="h-6 w-32 bg-gray-200 rounded"></div>
              </div>

              <div className="mt-12 space-y-4">
                <div className="h-5 bg-gray-200 rounded"></div>
                <div className="h-5 w-11/12 bg-gray-200 rounded"></div>
                <div className="h-5 w-10/12 bg-gray-200 rounded"></div>
              </div>

              <div className="mt-6">
                <div className="mt-10 flex items-center gap-4">
                  <div className="h-10 w-32 bg-gray-200 rounded"></div>
                  <div className="h-5 w-32 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

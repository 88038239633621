import React from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import RadioInput from "../forms/RadioInput";

export default function SideFilters({ options, setter, getter, name }) {
  const [showDropdown, setShowDropdown] = React.useState(true);
  return (
    <div className="bg-white px-6  pb-3 transition-all duration-500 ease-in-out max-h-64 overflow-scroll ">
      <div
        onClick={() => setShowDropdown(!showDropdown)}
        className="flex items-center justify-between cursor-pointer  sticky top-0 bg-white pt-3"
      >
        <h1 className=" font-medium sticky top-0">{name}</h1>
        <button onClick={() => setShowDropdown(!showDropdown)}>
          {showDropdown ? <FiChevronDown /> : <FiChevronUp />}
        </button>
      </div>

      {showDropdown ? (
        <ul className="pt-3 space-y-4 text-sm transition-all duration-500 ease-in-out">
          {options?.map((item) => (
            <li
              className="cursor-pointer flex items-center gap-3 text-sm"
              key={item.id}
              onClick={() => setter(item)}
            >
              <RadioInput checked={item.id === getter?.id} />
              {item.name}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

import React from "react";

export default function ThickChevron() {
  return (
    <>
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.951901 11.8244H8.5019C9.04357 11.8244 9.5519 11.5661 9.86023 11.1244L13.1519 6.48274C13.2536 6.34209 13.3083 6.17296 13.3083 5.99941C13.3083 5.82586 13.2536 5.65672 13.1519 5.51608L9.8519 0.866075C9.70103 0.648144 9.49914 0.470425 9.26384 0.348415C9.02853 0.226404 8.76695 0.163805 8.5019 0.166075H0.951901C0.276901 0.166075 -0.114766 0.941075 0.276901 1.49108L3.5019 5.99941L0.276901 10.5077C-0.114766 11.0577 0.276901 11.8244 0.951901 11.8244Z"
          fill="#282828"
        />
      </svg>
    </>
  );
}

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import "../../App.css";
import useActiveStoreKey from "../../hooks/useActiveStoreKey";
import { useAdmin } from "../../providers/AuthProvider";
import Footer from "../footer/Footer";
import Nav from "../nav/Nav";

export default function Layout() {
  const { user } = useAdmin();
  const storeKeys = useActiveStoreKey();

  const loginUrl =
    storeKeys?.id && storeKeys?.key
      ? `/${storeKeys?.id}/${storeKeys?.key}/login`
      : `/authorized`;

  return (
    <div className="min-h-full">
      <Nav />
      {/* <Header /> */}
      <main className="bg-[#E5E5E5] min-h-screen  ">
        <div className="">{user ? <Outlet /> : <Navigate to={loginUrl} />}</div>
      </main>{" "}
      <Footer />
    </div>
  );
}

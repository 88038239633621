const productUtils = {
  onSales(product) {
    if (product?.pricing.on_sales) {
      return true;
    }
    const variantOnSales = product?.variant_combinations?.find(
      (item) => item.on_sales
    );
    if (variantOnSales) {
      return true;
    }
    return false;
  },

  getPriceRange(product) {
    const variant_combinations = product.variant_combinations;

    if (variant_combinations?.length === 0) {
      const on_sales = this.onSales(product);
      if (on_sales) return product.pricing.sales_price;
      return product.pricing.current_price;
    }

    //sort variant combinations by price
    const sort = product?.variant_combinations?.sort((a, b) => {
      let aPrice = a.on_sales ? a.sales_price : a.current_price;
      let bPrice = b.on_sales ? b.sales_price : b.current_price;
      return aPrice - bPrice;
    });

    const lowestPrice = sort[0].on_sales
      ? sort[0].sales_price
      : sort[0].current_price;

    const highestPrice = sort[sort.length - 1].on_sales
      ? sort[sort.length - 1].sales_price
      : sort[sort.length - 1].current_price;

    //product has a uniform price
    if (lowestPrice === highestPrice) {
      return highestPrice;
    }

    return {
      lowestPrice,
      highestPrice,
      range: true,
    };
  },

  getSalesPriceRange(product) {
    const variant_combinations = product.variant_combinations;

    if (variant_combinations?.length === 0) {
      const on_sales = this.onSales(product);
      if (on_sales) return product.pricing.sales_price;
      return product.pricing.current_price;
    }

    //sort variant combinations by price
    const sort = product?.variant_combinations?.sort((a, b) => {
      let aPrice = a.on_sales ? a.sales_price : a.current_price;
      let bPrice = b.on_sales ? b.sales_price : b.current_price;
      return aPrice - bPrice;
    });

    const lowestPrice = sort[0].on_sales
      ? sort[0].sales_price
      : sort[0].current_price;

    const highestPrice = sort[sort.length - 1].on_sales
      ? sort[sort.length - 1].sales_price
      : sort[sort.length - 1].current_price;

    //product has a uniform price
    if (lowestPrice === highestPrice) {
      return highestPrice;
    }

    return {
      lowestPrice,
      highestPrice,
      range: true,
    };
  },

  getDiscountPriceRance(product) {
    const variant_combinations = product.variant_combinations;

    if (variant_combinations?.length === 0) {
      return product.pricing.current_price;
    }

    //sort variant combinations by price
    const sort = product?.variant_combinations?.sort((a, b) => {
      return a.current_price - b.current_price;
    });

    console.log({ sort });

    const lowestPrice = sort[0].current_price;

    const highestPrice = sort[sort.length - 1].current_price;

    //product has a uniform price
    if (lowestPrice === highestPrice) {
      return highestPrice;
    }

    return {
      lowestPrice,
      highestPrice,
      range: true,
    };
  },

  checkStatus(product) {
    // product has no variant use variants
    const variant_combinations = product.variant_combinations;
    if (variant_combinations?.length === 0) {
      if (product.inventory.track_quantity) {
        return product?.inventory?.quantity === 0 ? true : false;
      }
      return false;
    }

    // Product has variants
    const checkVariantQty = variant_combinations.find(
      (item) => !item.track_quantity
    );

    return checkVariantQty ? false : true;
  },

  serializeStock(product) {
    if (product.variant_combinations?.length === 0) {
      return product?.inventory?.quantity ? product?.inventory?.quantity : "-";
    }
    let lowestPrice;
    let highestPrice;

    const sort = product?.variant_combinations?.sort(
      (a, b) => a?.quantity - Number(b.quantity)
    );
    lowestPrice = sort[0].quantity;
    highestPrice = sort[sort.length - 1].quantity;

    if (lowestPrice === highestPrice) {
      return highestPrice;
    }

    return `${lowestPrice} - ${highestPrice}`;
  },

  getVariantPrice(_product, selectedVariant) {
    const product = _product;
    if (!product) {
      return;
    }

    const combinationName = selectedVariant
      ?.map((variant) => variant.entry)
      .join("/");
    const matchingCombination = product.variant_combinations.find(
      (combination) => combination.name === combinationName
    );

    if (matchingCombination) {
      return matchingCombination.on_sales
        ? matchingCombination.sales_price
        : matchingCombination.current_price;
    }

    return undefined;
  },

  getProductIds(products) {
    if (!products) return [];
    return products.map((product) => product.id);
  },

  updateProductImages(images, products) {
    if (!images) return;

    const updatedProducts = products?.map((product) => {
      if (images[product.id]) {
        product.image = images[product.id];
      }
      return product;
    });

    return updatedProducts;
  },
};

export default productUtils;

import React from "react";
import { IoIosClose } from "react-icons/io";

export default function Filters({ filter, name, onRemove }) {
  return (
    <div className="rounded-full border border-gray-300 flex items-center px-2 py-1 text-xs bg-white mb-4">
      <span className="font-medium mr-1">{filter}: </span> <span>{name}</span>
      <button onClick={onRemove}>
        <IoIosClose className="w-4 h-4 ml-2 text-red-700" />
      </button>
    </div>
  );
}

import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "../../providers/CartProvider";
import productUtils from "../../utils/product.utils";
import Price from "./Price";
import ProductImage from "./ProductImage";
import ProductStatusBadge from "./ProductStatusBadge";

function ProductCard({
  product,
  extras,
  setOpen,
  setSelectedProduct,
  addToCart,
}) {
  const handleAddToCart = (product) => {
    //Product has variant open variant modal
    if (product.variants?.length > 0) {
      setSelectedProduct({ ...product, qty: 1 });
      setOpen(true);
      return;
    }
    // No variant add to cart
    addToCart(product);
  };

  const price = productUtils.getPriceRange(product);
  const discountPrice = productUtils.getDiscountPriceRance(product);
  const onSales = productUtils.onSales(product);
  const soldOut = productUtils.checkStatus(product);
  const currency = getSymbolFromCurrency(extras?.currency_code);
  const { cart } = useCart();
  const inCart = (id) => {
    const product = cart.find((item) => item.id === id);
    return product;
  };

  return (
    <div key={product.id} className="grou relative bg-white py-4 ">
      {soldOut && (
        <ProductStatusBadge
          className={
            "bg-red-700 flex items-center px-2 text-white w-fit h-5 text-xs absolute inset-0 rounded-r-full"
          }
        >
          On Sales
        </ProductStatusBadge>
      )}
      {!soldOut && onSales && (
        <ProductStatusBadge
          className={
            "bg-green-700 flex items-center px-2 text-white w-fit h-5 text-xs absolute inset-0 rounded-r-full"
          }
        >
          On Sales
        </ProductStatusBadge>
      )}

      <div className="w-full overflow-hidden rounded-md lg:aspect-none group-hover:opacity-75 ">
        <Link to={`/details/${product.id}`}>
          <ProductImage
            image={product?.image}
            className="h-40 w-full object-contain object-"
          />
        </Link>
      </div>
      <div className="mt-4 text-center">
        <h3 className="text-xs text-gray-700 truncate-2 px-2 lg:px-4">
          <Link t to={`/details/${product.id}`}>
            {product.title}
          </Link>
        </h3>

        {onSales ? (
          <p className="mt-1 text-xs line-through text-gray-500">
            {currency}
            {discountPrice?.range ? (
              <>
                <Price>{discountPrice?.lowestPrice}</Price> - {currency}
                <Price>{discountPrice?.highestPrice}</Price>
              </>
            ) : (
              <Price>{discountPrice}</Price>
            )}
          </p>
        ) : null}

        <p className=" mt-1 text-sm font-medium text-gray-900">
          {currency}
          {price?.range ? (
            <>
              <Price>{price?.lowestPrice}</Price> - {currency}
              <Price>{price?.highestPrice}</Price>
            </>
          ) : (
            <Price>{price}</Price>
          )}
        </p>

        <button
          onClick={() => handleAddToCart(product)}
          className="focus:outline-none hover:text-gray-600 bg-transparent text-gray-800 text-[13px] font-medium"
        >
          {inCart(product.id) ? (
            <span className="text-blue-800">Added to cart</span>
          ) : (
            <span>Add to cart</span>
          )}
        </button>
      </div>
    </div>
  );
}

export default ProductCard;

import axios from "axios";
import Cookies from "js-cookie";
import StorageServices from "./storageServices";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 500000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  var userToken;
  if (Cookies.get("SB-buyer-token")) {
    userToken = Cookies.get("SB-buyer-token");
  }
  // if (Cookies.get("SB-business-token")) {
  //   userToken = Cookies.get("SB-business-token");
  // }
  var token = userToken ? userToken.replace(/['"]+/g, "") : null;
  const headers = {
    ...config,
    headers: {
      Authorization: token,
      clientId: "storebounty",
      Accept: "application/json",
      "Content-Type": "application/json",
      ...config.headers,
    },
  };
  //console.log('Headers - interecepted: ', JSON.stringify(headers));
  return headers;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      if (!window.location.pathname.includes("/login")) {
        StorageServices.clearAll();
        window.location.reload();
      }

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

const responseBody = (response) => response.data || response;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body, headers) =>
    instance.delete(url, body, headers).then(responseBody),
};

export default requests;

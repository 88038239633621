import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackButton from "../components/common/BackButton";
import Badge from "../components/common/Badge";
import WalletIcon from "../components/icons/WalletIcon";
import Price from "../components/products/Price";
import OrderServices from "../services/OrderServices";

export default function OrderDetails() {
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await OrderServices.getOrderbyId(id);
        console.log(res.data);
        setOrder(res.data?.order);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-6xl pt-6 px-4 mb-32">
        <BackButton />
        <div className="flex items-center justify-between mt-6">
          <h1 className="font-medium">
            {order?.id} <span className="text-xs font-light">(Order ID)</span>{" "}
          </h1>
          <button className="bg-black text-sm py-2 px-4 rounded text-white">
            Reorder
          </button>
        </div>

        <div className="grid lg:grid-cols-2 gap-4 mt-4">
          <div className="space-y-4">
            <div className="border rounded-lg">
              <div className=" border-b py-2 px-6">
                <span className="text-sm font-medium">Order details</span>
              </div>

              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Order date</p>
                <p className="text-sm font-medium">
                  {dayjs(order?.createdAt).format("MMM D, YYYY h:mm A")}
                </p>
              </div>
              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Discount </p>
                <p className="text-sm font-medium">{order?.discount}</p>
              </div>
              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Delivery charge</p>
                <p className="text-sm font-medium">
                  <Price>{order?.delivery_charge}</Price>
                </p>
              </div>
              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Payment gateway</p>
                <p className="text-sm font-medium">-</p>
              </div>
              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Total tax</p>
                <p className="text-sm font-medium">
                  <Price>{order?.vat}</Price>
                </p>
              </div>
              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Total amount paid</p>
                <p className="text-sm font-medium">
                  <Price>{order?.total}</Price>
                </p>
              </div>
              <div className="flex items-center justify-between px-6 py-3 ">
                <p className="text-sm text-gray-600">Order status</p>
                <Badge status={order?.status} />
              </div>
            </div>
            <div className="border rounded-lg">
              <div className=" border-b py-2 px-6">
                <span className="text-sm font-medium">Items</span>
              </div>

              <ul>
                {order?.items?.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between px-6 py-3 border-b border-gray-100"
                  >
                    <div className="flex gap-4">
                      <div className="bg-gray-200 rounded h-10 w-10">
                        <img
                          src={item.product_image}
                          alt=""
                          className="object-contain"
                        />
                      </div>
                      <div>
                        <p className="text-sm">{item.product_name}</p>
                        <p className="text-xs text-gray-600">{item.qty} pcs</p>
                        <p className="text-[10px] text-gray-600">
                          Size:{" "}
                          <span className="font-medium text-xs pl-[0.5px]">
                            XL
                          </span>
                        </p>
                      </div>
                    </div>

                    <p className="text-sm font-medium">
                      {getSymbolFromCurrency(order.currency_code)}
                      <Price>{item.price}</Price>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="space-y-4">
            <div className="border rounded-lg">
              <div className=" border-b py-2 px-6">
                <span className="text-sm font-medium">Payments</span>
              </div>

              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Total</p>
                <p className="text-sm font-medium">
                  {getSymbolFromCurrency(order?.currency_code)}
                  <Price>{order?.total}</Price>
                </p>
              </div>
              {order?.online_payments?.map((item) => (
                <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                  <div className="flex items-center gap-2">
                    <WalletIcon />
                    <div>
                      <p className="text-sm text-gray-600">Discount </p>
                      <div className="lg:hidden">
                        <Badge status={item.status} />
                      </div>
                    </div>
                  </div>
                  <div className="hidden lg:block">
                    <Badge status={item.status} />
                  </div>
                  <div className="hidden lg:block">
                    <p className="text-sm text-gray-600">
                      {dayjs(item.completed).format("MMM D, YYYY h:mm A")}
                    </p>
                  </div>
                  <div className="flex flex-col items-end">
                    <p className="text-sm font-medium">
                      {getSymbolFromCurrency(item.currency_code)}
                      <Price>{item.amount}</Price>
                    </p>{" "}
                    <p className="text-sm text-gray-600 lg:hidden">
                      {dayjs(item.completed).format("MMM D, YYYY h:mm A")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="border rounded-lg">
              <div className=" border-b py-2 px-6">
                <span className="text-sm font-medium">Customer</span>
              </div>

              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Name</p>
                <p className="text-sm font-medium">
                  {order?.customer?.first_name} {order?.customer?.last_name}
                </p>
              </div>
              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Phone number </p>
                <p className="text-sm font-medium">
                  {order?.customer?.phone || "-"}
                </p>
              </div>
              <div className="flex items-center justify-between px-6 py-3 border-b border-gray-100">
                <p className="text-sm text-gray-600">Email address</p>
                <p className="text-sm font-medium">
                  {order?.customer?.email || "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

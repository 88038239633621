import requests from "./httpsServices";
import StorageServices from "./storageServices";

const AddressServices = {
  get() {
    return requests.get(`/accounts/api/v1/customers/addresses`);
  },
  getBuyerOutlets() {
    const storeId = this.getBuyerStoreId();
    return requests.get(`/marketplace/v1/b2b/${storeId}/outlets`);
  },
  getSellerOutlets() {
    const storeId = this.getSellerStoreId();
    return requests.get(`/marketplace/v1/b2b/${storeId}/outlets`);
  },

  create(payload) {
    return requests.post(`/accounts/api/v1/customers/addresses/add`, payload);
  },

  update(payload) {
    return requests.put(`/accounts/api/v1/customers/addresses/update`, payload);
  },

  getCountries() {
    return requests.get(`/accounts/api/v1/public/countries`);
  },

  getSellerStoreId() {
    const sellerStore = StorageServices.get("active-sellers-store");
    if (sellerStore) {
      return JSON.parse(sellerStore)?.id;
    }
  },

  getBuyerStoreId() {
    const buyerstore = StorageServices.get("active-buyer-store");
    if (buyerstore) {
      return JSON.parse(buyerstore)?.id;
    }
  },
};

export default AddressServices;

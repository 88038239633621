import { Link } from "react-router-dom";
import SucessPng from "../../assets/images/Achievement-pana.png";
import useActiveStoreKey from "../../hooks/useActiveStoreKey";
import useSellerStore from "../../hooks/useSellerStore";
export default function ResetSuccess() {
  const store = useSellerStore();
  const storeKeys = useActiveStoreKey();

  return (
    <>
      <div className="min-h-screen flex divide-x">
        <div className="hidden relative  flex-1 w-1/2 lg:flex flex-col justify-center items-center">
          <div className="rounded-full bg-gray-50 h-24 w-24">
            <img
              src={store?.base_url + store?.logo_key}
              alt=""
              className="w-full"
            />
          </div>
          <h1 className="font-medium text-2xl mt-3">{store?.name}</h1>

          <div className="absolute bottom-0 mb-10">
            <p className="text-center text-sm">Powered by</p>
            <img src="/logo.svg" alt="" className="w-40" />
          </div>
        </div>
        <div className="w-1/2 flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-72">
            <div className="mt-6 ">
              <img src={SucessPng} alt="" className="w-48 mx-auto" />

              <p className="text-center text-xl font-medium mt-3">Success!!!</p>
              <p className="mt-3 text-sm text-center">
                You have successfully reset your password. log in to access your
                store.
              </p>
              <div className="mt-6 px-6">
                <Link to={`/${storeKeys.id}/${storeKeys.key}/login`}>
                  <button
                    type="submit"
                    className="w-full  flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  >
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function HomeLoading() {
  return (
    <div>
      <div className=" grid grid-cols-2 gap-x-2 gap-y-10 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-4">
        {Array.from({ length: 8 }).map((item) => (
          <div
            key={item}
            className="group flex flex-col justify-center items-center relative bg-white py-4 animate-pulse "
          >
            <div className="h-32 w-32 bg-gray-200 rounded"></div>

            <div className="mt-6 h-3 w-32 bg-gray-200 rounded-full"></div>

            <div className="mt-4 h-4 w-16 bg-gray-200 rounded-full"></div>

            <div className="mt-3 h-3 w-16 bg-gray-200 rounded-full"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

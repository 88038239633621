import React from "react";

export default function RadioInput({
  className,
  name,
  value,
  onChange,
  checked,
  id,
}) {
  return (
    <input
      type={"radio"}
      className={`w-4 h-4 focus:outline-none ${className}`}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      id={id}
    />
  );
}

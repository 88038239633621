import React from "react";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";

export default function AddToCartButton({
  quantity,
  decreaseQuantity,
  increaseQuantity,
}) {
  return (
    <>
      <div className="flex items-center gap-2">
        <button onClick={() => decreaseQuantity()}>
          <AiFillMinusCircle className="w-5 h-5" />
        </button>
        <span className="text-xs">{quantity}</span>
        <button onClick={() => increaseQuantity()}>
          <AiFillPlusCircle className="w-5 h-5" />
        </button>
      </div>
    </>
  );
}

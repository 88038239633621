import React from "react";

export default function PackageIcon() {
  return (
    <svg
      width="144"
      height="124"
      viewBox="0 0 144 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.9915 95.0824L71.0367 74.0452L60.8136 98.2558L22.5086 81.4973L23.4 101.394L71.4697 123.674L127.043 96.3456L126.278 70.9278L79.9915 95.0824ZM143.032 16.7201L87.3367 0.226562L72.03 13.8269L56.2903 1.81581L0.865234 24.7682L20.2011 40.2889L2.72955 67.3011L58.1495 91.5269L71.0367 60.9746L82.5282 88.0122L140.561 57.7452L124.745 33.7536L143.042 16.715L143.032 16.7201ZM70.8177 57.2664L24.6683 37.7573L71.918 16.8627L120.854 33.4429L70.8177 57.2664Z"
        fill="#DCE4EF"
      />
    </svg>
  );
}

import StorageServices from "../services/storageServices";

const store = StorageServices.get("active-sellers-store")
  ? JSON.parse(StorageServices.get("active-sellers-store"))
  : {};

export const findLogisticsByLocalArea = (
  countryCode,
  regionCode,
  localAreaCode
) => {
  const matchingLogistics = [];

  store.shipping_services?.forEach((logistic) => {
    const country = logistic.countries.find(
      (country) => country.code === countryCode
    );

    if (!country) return;

    const region = country.regions.find((region) => region.code === regionCode);

    if (!region) return;

    const localArea = region.local_areas.find(
      (localArea) => localArea.code === localAreaCode
    );

    if (!localArea) return;

    const logisticsWithPricing = {
      id: logistic.id,
      name: logistic.name,
      info: logistic.info,
      cost: localArea.base_cost,
      handlingCost: localArea.handling_cost,
      costPerMetric: localArea.cost_per_metric,
      costMetric: localArea.cost_metric,
      durationMin: localArea.duration_min,
      durationMax: localArea.duration_max,
      durationMetric: localArea.duration_metric,
    };

    matchingLogistics.push(logisticsWithPricing);
  });
  console.log({ matchingLogistics });
  return matchingLogistics;
};

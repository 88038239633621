import requests from "./httpsServices";
import StorageServices from "./storageServices";

const ProductServices = {
  get() {
    const storeId = this.getSellerStoreId();
    return requests.get(`/marketplace/v1/b2b/${storeId}/products`);
  },
  getById(id) {
    const storeId = this.getSellerStoreId();
    return requests.get(`/marketplace/v1/b2b/${storeId}/products/${id}`);
  },
  getBySearch(filters) {
    const storeId = this.getSellerStoreId();
    return requests.get(`/marketplace/v1/b2b/${storeId}/products?${filters}`);
  },

  getProductImages(ids) {
    const storeId = this.getSellerStoreId();
    const url = `/marketplace/v1/public/${storeId}/media/fetch?entity=product&ids=${ids}&size=medium&all=true`;
    return requests.get(url);
  },

  getProductImage(id) {
    const storeId = this.getSellerStoreId();
    const url = `/marketplace/v1/public/${storeId}/media/fetch/${id}?entity=product&size=medium&all=true`;
    return requests.get(url);
  },

  getSellerStoreId() {
    const sellerStore = StorageServices.get("active-sellers-store");
    if (sellerStore) {
      return JSON.parse(sellerStore)?.id;
    }
  },
};

export default ProductServices;

import React from "react";
import unauthorizedpng from "../assets/images/unauthorized.png";
import unauthorizedbg from "../assets/images/unauthorizedbg.png";

export default function UnAuthorized() {
  return (
    <div
      style={{ backgroundImage: `url(${unauthorizedbg})` }}
      className={`min-h-screen bg-cover bg-no-repeat bg-center`}
    >
      <div className="grid grid-cols-2 min-h-screen">
        <div className="flex items-center justify-center h-full">
          <img src={unauthorizedpng} alt="unauthorized" />
        </div>
        <div className="flex flex-col  justify-center h-full">
          <h1 className="font-medium text-xl">Authorization Required</h1>
          <p className="mt-2 text-sm lg:w-8/12">
            Please check your email for the right link to access this
            application. If you need more information contact the business
            owner.
          </p>
          {/* <div className="">
            <a
              href="mailto:Info@Storebounty.com"
              target="_blank"
              rel="noreferrer"
              className="max-w-[200px] flex items-center justify-center space-x-2  ring-1 ring-offset-2 ring-black  rounded-full bg-black text-white py-1 px-4 text-sm mt-6"
            >
              <span>Info@Storebounty.com</span>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

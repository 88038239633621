import React from "react";

export default function CardLocationIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.125 15C20.75 15 18.75 17 18.75 19.375C18.75 22.625 23.125 27.5 23.125 27.5C23.125 27.5 27.5 22.625 27.5 19.375C27.5 17 25.5 15 23.125 15ZM23.125 21C22.25 21 21.625 20.25 21.625 19.5C21.625 18.625 22.375 18 23.125 18C23.875 18 24.625 18.75 24.625 19.5C24.75 20.25 24 21 23.125 21ZM18.1875 25H5C4.33696 25 3.70107 24.7366 3.23223 24.2678C2.76339 23.7989 2.5 23.163 2.5 22.5V7.5C2.5 6.83696 2.76339 6.20107 3.23223 5.73223C3.70107 5.26339 4.33696 5 5 5H25C26.3875 5 27.5 6.1125 27.5 7.5V14.125C26.7687 13.5073 25.9175 13.0477 25 12.775V10H5V13.75H19.2375C17.45 15 16.25 17.075 16.25 19.375C16.25 21.25 17.15 23.2625 18.1875 25Z"
        fill="#0230B1"
      />
    </svg>
  );
}

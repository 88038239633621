import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      type="button"
      className=" flex items-center text-sm"
    >
      <BiChevronLeft className="h-5 w-5" /> Back
    </button>
  );
}

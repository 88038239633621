import { useState } from "react";
import PinInput from "react-pin-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../components/common/BackButton";
import AuthLayout from "../../components/layout/AuthLayout";
import { useAdmin } from "../../providers/AuthProvider";
import { notifyError } from "../../utils/toast";

export default function ConfirmOTP() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAdmin();
  const [otp, setOtp] = useState("");

  if (user) {
    return <Navigate to={"/"} />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!otp || otp.length < 4) {
      notifyError("OTP is required");
      return;
    }
    navigate("/reset-password", {
      state: {
        email: location?.state?.email,
        otp: otp,
      },
    });
  };

  return (
    <>
      <AuthLayout>
        <div className="lg:w-1/2 flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <BackButton />

          <div className="mx-auto w-full max-w-sm lg:w-72">
            <div>
              <h2 className="mt-6 text-2xl text-center font-medium text-gray-900">
                Forgot Password
              </h2>
            </div>

            <hr className="mt-6" />

            <div className="mt-6 ">
              <p className="text-sm">Enter the 4-digit OTP sent to</p>
              <div className="mt-6">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm text-blue-800 "
                    >
                      {location?.state?.email}
                    </label>

                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      // ref={(n) => (ele = n)}
                      value={otp}
                      onChange={(e) => setOtp(e)}
                      type="numeric"
                      inputMode="number"
                      style={{
                        display: "flex",
                        gap: "10px",
                        margin: "20px 0px",
                      }}
                      inputStyle={{
                        borderColor: "gray",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        width: "60px",
                        height: "60px",
                      }}
                      inputFocusStyle={{
                        borderColor: "rgba(9, 29, 143, 0.3)",
                        // border: "1px solid rgba(9, 29, 143, 0.3)",
                        boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
                        borderRadius: "4px",
                      }}
                      // onComplete={(value, index) => {}}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                  </div>

                  <div className="text-sm flex gap-2 items-center">
                    Did not receive OTP?{" "}
                    <button className="text-blue-800">Resend OTP</button>
                  </div>

                  <hr className="mt-6" />

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                    >
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

import Cookies from "js-cookie";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../providers/AuthProvider";
import AuthServices from "../services/AuthServices";
import StorageServices from "../services/storageServices";
import { notifyError, notifySuccess } from "../utils/toast";
import useBuyerStore from "./useBuyerStore";
import useActiveStore from "./useSellerStore";

export default function useLoginSubmit() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const { setUser } = useAdmin();
  const navigate = useNavigate();
  const store = useActiveStore();
  const distributor = useBuyerStore();
  const [loading, setLoading] = useState(false);

  const handleLoginSubmit = async (data) => {
    try {
      setLoading(true);
      const { username, password, remember_me } = data;
      let token;
      const res = await AuthServices.login(username, password);

      token = res?.token;
      if (distributor.type === "internal") {
        const exchangeToken = await AuthServices.ExchangeToken(token);
        token = exchangeToken?.data.business_token;
      }
      const user = res.data?.customer || res.data?.info;
      if (token) {
        if (remember_me) {
          StorageServices.set("SB-remember-me", remember_me);
        } else {
          StorageServices.remove("SB-remember-me");
        }
        StorageServices.set("logged-in-email", username);
        StorageServices.set("SB-buyer-info", JSON.stringify(user));
        setUser(user);
        Cookies.set("SB-buyer-token", "Bearer " + token);
        navigate(`/`);
      }
    } catch (err) {
      const message =
        err.response?.data?.message ||
        err.response?.data?.data?.message ||
        err.message ||
        err.toString();
      notifyError(message);
    } finally {
      setLoading(false);
    }
  };

  const onsendResetOtp = async (data, e) => {
    if (!data.email) {
      return;
    }
    const payload = {
      app_id: "storebounty",
      business_id: store.id,
      email: data.email,
    };
    setLoading(true);
    try {
      const otp = await AuthServices.sendRestOTP(payload);
      notifySuccess(otp.message);
      navigate("/confirm-otp", {
        state: {
          email: data.email,
        },
      });
      // console.log(res);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
    } finally {
      setLoading(false);
    }
  };

  const onResetPassword = async (data, e) => {
    if (!data.email) {
      return;
    }
    const payload = {
      app_id: "storebounty",
      business_id: store.id,
      otp: data.otp,
      email: data.email,
      new_password: data.new_password,
      repeat_password: data.repeat_password,
    };
    setLoading(true);
    try {
      const res = await AuthServices.resetPassword(payload);
      notifySuccess(res?.data?.message);
      navigate("/reset-success");
      // console.log(res);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
    } finally {
      setLoading(false);
    }
  };

  return {
    register,
    handleSubmit,
    handleLoginSubmit,
    Controller,
    control,
    onsendResetOtp,
    onResetPassword,
    setValue,
    loading,
    errors,
  };
}

import { Tab } from "@headlessui/react";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useMemo } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { findLogisticsByLocalArea } from "../../utils/checkout.utils";
import Label from "../forms/Label";
import RadioInput from "../forms/RadioInput";
export default function DeliveryOptions({
  store,
  deliveryOption,
  setDeliveryOption,
  pickupAddress,
  setPickupAddress,
  logisticsCoy,
  setLogisticsCoy,
  deliveryAddress,
  calculateShippingCost,
}) {
  const handleDeliveryOptionChange = (e) => {
    setPickupAddress("");
    setDeliveryOption(e.target.value);
  };

  const handlePickupAddressChange = (e) => {
    setPickupAddress(e.target.value);
  };

  const handleLogisticsCoyChange = (e) => {
    setLogisticsCoy(e.target.value);
  };

  const logisticsList = useMemo(() => {
    const countryCode = deliveryAddress?.country_code;
    const regionCode = deliveryAddress?.region_code;
    const localAreaCode = deliveryAddress?.local_area_code;

    return findLogisticsByLocalArea(countryCode, regionCode, localAreaCode);
  }, [deliveryAddress]);

  // console.log({ logisticsCoy });
  const logisticsCoyDetails = useMemo(() => {
    if (!logisticsCoy) return {};
    return JSON.parse(logisticsCoy);
  }, [logisticsCoy, deliveryAddress]);

  return (
    <div className="p-7 border mt-6">
      <h1>Delivery</h1>

      <Tab.Group>
        <Tab.List className="flex items-center gap-8  ">
          <Tab className={"focus:outline-none"}>
            <div className="flex items-center gap-2 mt-2">
              <RadioInput
                name="delivey_option"
                value={"doorstep"}
                onChange={handleDeliveryOptionChange}
                checked={deliveryOption === "doorstep"}
              />
              <Label>Doorstep Delivery</Label>
            </div>
          </Tab>
          <Tab className={"focus:outline-none"}>
            <div className="flex items-center gap-2 mt-2">
              <RadioInput
                name="delivey_option"
                value={"pickup"}
                onChange={handleDeliveryOptionChange}
                checked={deliveryOption === "pickup"}
              />
              <Label>Store Pick Up</Label>
            </div>
          </Tab>
        </Tab.List>

        {deliveryOption ? (
          <Tab.Panels>
            <Tab.Panel>
              <div className="pt-4 grid lg:grid-cols-2 gap-5 mt-3 ">
                <div className="lg:col-span-2">
                  <p className="text-sm ">Select Logistic company</p>
                </div>
                {logisticsList?.map((item) => (
                  <div key={item.id} className="flex gap-2 w-full">
                    <RadioInput
                      name="logistics"
                      value={JSON.stringify(item)}
                      onChange={handleLogisticsCoyChange}
                      checked={
                        logisticsCoy
                          ? JSON.parse(logisticsCoy)?.id === item.id
                          : false
                      }
                    />
                    <div className="flex items-center  gap-2  border p-4  rounded w-full">
                      <div>
                        <TbTruckDelivery className=" text-gray-500" />
                      </div>
                      <div>
                        <p className="font-medium text-sm"> {item.name}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>{" "}
              {logisticsList?.length === 0 ? (
                <p className="text-xs text-red-400 mt-4">
                  No Logistics Available for this location
                </p>
              ) : null}
              <div className="mt-4">
                {logisticsCoy ? (
                  <p className="text-xs">
                    Delivery Fee: {getSymbolFromCurrency(store.currency_code)}
                    {logisticsCoyDetails ? calculateShippingCost() : null}
                    <span className="ml-4">
                      Shipping Duration: {logisticsCoyDetails.durationMin}-
                      {logisticsCoyDetails.durationMax}{" "}
                      {logisticsCoyDetails.durationMetric}
                    </span>
                  </p>
                ) : null}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="pt-4 grid lg:grid-cols-2 gap-5 mt-3 ">
                <div className="lg:col-span-2">
                  <p className="text-sm ">Select Pickup Location</p>
                </div>
                {store.pickup_locations?.map((item) => (
                  <div key={item.id} className="flex gap-2 w-full">
                    <RadioInput
                      name="pickup_address"
                      value={JSON.stringify(item)}
                      onChange={handlePickupAddressChange}
                      checked={
                        pickupAddress
                          ? JSON.parse(pickupAddress)?.id === item.id
                          : false
                      }
                    />
                    <div className="flex  gap-2  border p-4  rounded w-full">
                      <div>
                        <IoLocationSharp className=" text-gray-500" />
                      </div>
                      <div>
                        <p className="font-medium text-sm"> {item.name}</p>
                        <p className="text-xs text-gray-500 mt-1">
                          {" "}
                          {item.street_1}
                        </p>
                        <p className="text-xs text-gray-500 mt-2">
                          {" "}
                          {item.street_2}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        ) : null}
      </Tab.Group>
    </div>
  );
}

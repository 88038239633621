import { createContext, useContext, useState } from "react";
import StorageServices from "../services/storageServices";

const AuthContext = createContext();

export const useAdmin = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(() =>
    StorageServices.get("SB-buyer-info")
      ? JSON.parse(StorageServices.get("SB-buyer-info"))
      : null
  );

  const value = { user, setUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

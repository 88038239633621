import { useEffect, useState } from "react";
import ProductServices from "../services/ProductServices";
import productUtils from "../utils/product.utils";
import useDebounce from "./useDebounce";

export default function useProductFilter(data) {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [collection, setCollection] = useState("");
  const [page, setPage] = useState("");
  const [searchResult, setSearchResult] = useState(data);
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    if (!debouncedValue && !category && !page && !brand && !collection) {
      return setSearchResult(data);
    }
    handleSearch();

    //eslint-disable-next-line
  }, [debouncedValue, category, page, data, brand, collection]);

  const searchQuery = () => {
    let query = {};
    if (debouncedValue) {
      query.q = debouncedValue;
    }
    if (category) {
      query.categoryId = category?.id;
    }
    if (brand) {
      query.brandId = brand?.id;
    }
    if (collection) {
      query.collectionId = collection?.id;
    }
    if (page) {
      query.page = page;
    }
    return query;
  };

  const handleSearch = async (e) => {
    try {
      const searchUrl = new URLSearchParams(searchQuery())?.toString();

      const res = await ProductServices.getBySearch(searchUrl);
      setSearchResult(res?.data);

      const ids = productUtils.getProductIds(res.data.content);
      const images = await ProductServices.getProductImages(ids);
      const medias = images.data.content;
      const products = res.data.content;
      const updatedProductsImages = productUtils.updateProductImages(
        medias,
        products
      );

      setSearchResult((prev) => ({
        ...prev,
        content: updatedProductsImages,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  return {
    searchResult,
    search,
    setSearch,
    category,
    setCategory,
    page,
    setPage,
    brand,
    setBrand,
    collection,
    setCollection,
  };
}

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdDashboard, MdShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import placeholderImg from "../../assets/images/Achievement-pana.png";
export default function SuccessModal({ open }) {
  return (
    <Transition.Root show={open} onClose={() => {}} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
        <div
          className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
          style={{ fontSize: 0 }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:align-middle md:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-xl md:px-4 md:my-8 md:align-middle lg:max-w-3xl">
              <div className="w-full relative flex items-center bg-white  overflow-hidden shadow-2xl">
                <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-3">
                  <div className="h-full w-full p-6 flex justify-center">
                    <img src={placeholderImg} alt="" />
                  </div>
                  <div className="col-span-2  lg:p-8 p-6">
                    <div>
                      <p className="font-medium">Payment Successful</p>
                      <p className="text-sm text-gray-500 mt-4 w-10/12">
                        Thanks for your purchase, You can choose to continue
                        shopping or return to your dashboard.
                      </p>
                    </div>

                    <div className="flex items-center gap-2 mt-6 w-11/12">
                      <Link to={"/"}>
                        <button
                          type="button"
                          className={
                            "bg-white text-black border w-full py-2  px-4 rounded border-black text-sm flex gap-2 items-center justify-center "
                          }
                        >
                          <span>Return to Dashboard</span> <MdDashboard />
                        </button>
                      </Link>
                      <Link to={"/"}>
                        <button
                          type="button"
                          className={
                            "bg-black  text-white  w-full py-2  px-4 rounded  text-sm font-medium flex gap-2 items-center justify-center"
                          }
                        >
                          <span>Continue Shopping</span> <MdShoppingCart />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BiUser } from "react-icons/bi";
import { BsBox2 } from "react-icons/bs";
import { FiChevronDown, FiLogOut, FiRefreshCw } from "react-icons/fi";
import { RiUserFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import StorageServices from "../../services/storageServices";
export default function ProfileDropDown() {
  const handleLogOut = () => {
    StorageServices.clearAll();
    window.location.reload();
  };
  const store = StorageServices.get("active-buyer-store")
    ? JSON.parse(StorageServices.get("active-buyer-store"))
    : null;
  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex gap-2 max-w-xs items-center text-sm font-medium">
            <span className="sr-only">Open user menu</span>

            <RiUserFill />
            <span className="truncate">Hi, {store?.name}</span>
            <FiChevronDown />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 font-medium">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={"/account"}
                    className={`text-gray-900 group flex w-full gap-2 items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <BiUser />
                    My Account
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={"/orders"}
                    className={`text-gray-900 group flex w-full gap-2 items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <BsBox2 />
                    Orders
                  </Link>
                )}
              </Menu.Item>
              {store?.distributor?.type === "external" && (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={"/change-password"}
                      className={`text-gray-900 group flex w-full gap-2 items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <FiRefreshCw />
                      Change password
                    </Link>
                  )}
                </Menu.Item>
              )}

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleLogOut}
                    className={`text-gray-900 group flex w-full gap-2 items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <FiLogOut />
                    Log Out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

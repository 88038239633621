import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import { BsFillTagsFill } from "react-icons/bs";
import { IoMdWallet } from "react-icons/io";
import { MdCreditCardOff } from "react-icons/md";
import BackButton from "../components/common/BackButton";
import CardLocation from "../components/icons/CardLocation";
import ThickChevron from "../components/icons/ThickChevron";
import useStoreObjects from "../hooks/useStoreObjects";
export default function Account() {
  const store = useStoreObjects();

  return (
    <div className="bg-white min-h-screen">
      <div className="mx-auto px-4 max-w-5xl">
        <div className="mt-2">
          <BackButton />
        </div>
        <h1 className="font-medium text-xl my-4">My Account</h1>
        <div className="container  border mt-4 bg-[#F9F9F9] rounded">
          <div className="py-3 border-b px-8">
            <h1 className="font-medium">Profile Details</h1>
          </div>
          <div className="grid lg:grid-cols-5 gap-3 p-8">
            <div className="h-[90px] w-[90px] rounded-full bg-gray-100">
              <img src="" alt="" />
            </div>
            <div className="lg:col-span-5">
              <label className="font-light text-xs">Company Name</label>
              <p className="font-semibold ">{store?.distributor?.name}</p>
            </div>
            <div className="lg:col-span-3">
              <label className="font-light text-xs">Address</label>
              <p className="text-sm font-medium">
                {store?.distributor?.address || "-"}
              </p>
            </div>
            <div className="lg:col-span-2">
              <label className="font-light text-xs">Phone</label>
              <p className="text-sm font-medium">
                {store?.distributor?.contact_phone || "-"}
              </p>
            </div>
            <div className="lg:col-span-3">
              <label className="font-light text-xs">Email</label>
              <p className="text-sm font-medium">
                {store?.distributor?.contact_email || "-"}
              </p>
            </div>
            {store?.distributor?.type === "external" ? (
              <div className="lg:col-span-2">
                <label className="font-light text-xs">Website</label>
                <p className="text-sm font-medium">
                  {store?.distributor?.website || "-"}
                </p>
              </div>
            ) : (
              <div className="lg:col-span-2">
                <label className="font-light text-xs">Store ID</label>
                <p className="text-sm font-medium">
                  {store?.distributor?.id || "-"}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="container  border mt-6 bg-[#F9F9F9] rounded">
          <div className="py-3 border-b px-8">
            <h1 className="font-medium">Discounts & Offers</h1>
          </div>
          <div className=" divide-y px-8 py-4">
            <div className="flex items-center justify-between flex-wrap gap-2 py-3">
              <label className="text-sm flex items-center gap-2">
                <ThickChevron />
                Credit Purchase
              </label>
              <p className="text-sm flex items-center gap-2">
                <span>
                  <IoMdWallet className="text-blue-800 w-5 h-5" />
                </span>
                <span>
                  Credit period of {store?.distributor?.credit_period} days to
                  the tune of{" "}
                  {getSymbolFromCurrency(store?.store?.currency_code) +
                    store?.distributor?.max_credit}
                </span>
              </p>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2 py-3">
              <label className="text-sm flex items-center gap-2">
                <ThickChevron />
                Discounts
              </label>
              <p className="text-sm flex items-center gap-2">
                <BsFillTagsFill className="text-blue-800  w-5 h-5" />
                {store?.discount ? (
                  <span>
                    You have{" "}
                    {store?.discount?.target === "VOL"
                      ? getSymbolFromCurrency(store?.store?.currency_code) +
                        store?.discount?.minimum_purchase_vol
                      : store?.discount?.minimum_purchase_qty + "%"}{" "}
                    <span className="uppercase">{store?.discount?.code}</span>{" "}
                    discount.
                  </span>
                ) : (
                  <span className="text-sm">None</span>
                )}
              </p>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2 py-3">
              <label className="text-sm flex items-center gap-2">
                <ThickChevron />
                Pay On Delivery
              </label>
              <p className="text-sm flex items-center gap-2">
                <CardLocation className="text-blue-800" />
                {store?.distributor?.allow_pod ? "YES" : "NO"}
              </p>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2 py-3">
              <label className="text-sm flex items-center gap-2">
                <ThickChevron />
                Free Delivery
              </label>
              <p className="text-sm flex items-center gap-2">
                <MdCreditCardOff className="text-blue-800  w-5 h-5" />
                {store?.distributor?.free_delivery ? "YES" : "NO"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Tab } from "@headlessui/react";
import React, { useEffect, useMemo, useState } from "react";
import { HiShoppingBag } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import AddToCartButton from "../components/common/AddToCartButton";
import DetailLoading from "../components/loadings/DetailLoading";
import Currency from "../components/products/Currency";
import Price from "../components/products/Price";
import ProductImage from "../components/products/ProductImage";
import SelectVariant from "../components/products/SelectVariant";
import { useCart } from "../providers/CartProvider";
import ProductServices from "../services/ProductServices";
import { notifyError } from "../utils/toast";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DetailPage() {
  const { id } = useParams();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [qty, setQty] = useState(1);
  const { addToCart, cart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    const fetchProducts = async () => {
      try {
        const res = await ProductServices.getById(id);
        const product = res.data.product;
        setData(product);
        const images = await ProductServices.getProductImage(id);
        const medias = images.data.content;
        setData({
          ...product,
          images: medias,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [id]);

  const variantPrice = useMemo(() => {
    const product = data;
    if (!product) {
      return;
    }

    const combinationName = selectedVariant
      ?.map((variant) => variant.entry)
      .join("/");
    const matchingCombination = product.variant_combinations.find(
      (combination) => combination.name === combinationName
    );

    if (matchingCombination) {
      return matchingCombination.on_sales
        ? matchingCombination.sales_price
        : matchingCombination.current_price;
    }

    return undefined;
  }, [data, selectedVariant]);

  if (loading) {
    return <DetailLoading />;
  }

  const increaseQuantity = () => {
    setQty(qty + 1);
  };

  const decreaseQuantity = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  return (
    <div className=" max-w-7xl mx-auto py-6 px-4 lg:px-0">
      <h2 className="font-bold text-xl pb-4">Product Details</h2>
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            {/* Image gallery */}

            {data?.images?.length === 0 ? (
              <HiShoppingBag
                className={` w-full object-center h-80 object-contain text-gray-200 `}
              />
            ) : (
              <Tab.Group as="div" className="flex flex-col-reverse">
                {/* Image selector */}
                <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-4 gap-6">
                    {data?.images?.map((image) => (
                      <Tab
                        key={image.media_id}
                        className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only">{image.file_name}</span>
                            <span className="absolute inset-0 rounded-md overflow-hidden">
                              <ProductImage
                                image={image.url}
                                alt={data?.title}
                                className="w-full h-full object-center object-cover "
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-indigo-500"
                                  : "ring-transparent",
                                "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>

                <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                  {data?.images?.map((image) => (
                    <Tab.Panel key={image.media_id}>
                      <ProductImage
                        image={image.url}
                        alt={data?.title}
                        className="w-full h-full object-center object-cover"
                      />
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            )}

            {/* Product info */}
            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <h1 className="text-2xl  font-light tracking-tight text-gray-900">
                {data?.title}
              </h1>

              <div className="mt-3">
                <h2 className="sr-only">Product information</h2>
                <p className="text-2xl text-gray-900">
                  <Currency currency={data?.extras?.currency_code} />
                  <Price>{variantPrice || data?.price}</Price>
                </p>
              </div>

              <div className="mt-6">
                <h6 className="text-xs">Quantity</h6>
                <AddToCartButton
                  quantity={qty}
                  increaseQuantity={increaseQuantity}
                  decreaseQuantity={decreaseQuantity}
                />
              </div>

              <div className="mt-6">
                {data?.variants?.map((variant) => (
                  <SelectVariant
                    variant={variant}
                    setSelectedVariant={setSelectedVariant}
                    selectedVariant={selectedVariant}
                  />
                ))}
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>

                <div
                  className="text-sm text-gray-700 space-y-6"
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                />
              </div>

              <div className="mt-6">
                <div className="mt-10 flex sm:flex-col1">
                  {/* <Link to={"/cart"}> */}
                  <button
                    onClick={() => {
                      if (selectedVariant.length < data?.variants.length) {
                        notifyError("Variant is missing");
                        return;
                      }
                      addToCart(data, qty, selectedVariant);
                      setSelectedVariant([]);
                      setQty(1);
                    }}
                    type="button"
                    className="max-w-xs flex-1 bg-black border border-transparent py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full"
                  >
                    Add to bag
                  </button>
                  {/* </Link> */}
                  <button
                    onClick={() => {
                      if (selectedVariant.length < data?.variants.length) {
                        notifyError("Variant is missing");
                        return;
                      }
                      addToCart(data, qty, selectedVariant);
                      setSelectedVariant([]);
                      setQty(1);
                      navigate("/cart");
                    }}
                    type="button"
                    className="ml-4 py-3 px-3 rounded-md flex items-center justify-center text-gray-700 hover:bg-gray-100 hover:text-gray-500"
                  >
                    Buy Now
                  </button>
                </div>
              </div>

              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>
              </section>
            </div>
          </div>
        </div>

        <div className="max-w-2xl mx-auto px-4 sm:px-6 pb-24 lg:max-w-7xl lg:px-8 overflow-scroll">
          <h2 className="font-medium mb-6 ">Related Products</h2>

          <div className="flex overflow-x-scroll">
            <div className="flex flex-wrap flex-1 w-2/4 lg:w-1/4   gap-4">
              <img
                src={
                  "https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg"
                }
                alt={""}
                className="object-center object-cover max-h-24"
              />
              <div>
                <p className="text-sm font-medium ">Product Name</p>
                <p className="text-xs text-gray-500 mt-4">Women, Lounge wear</p>
                <p className="text-xs font-medium mt-2">N4,500.00</p>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 w-2/4 lg:w-1/4   gap-4">
              <img
                src={
                  "https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg"
                }
                alt={""}
                className="object-center object-cover max-h-24"
              />
              <div>
                <p className="text-sm font-medium ">Product Name</p>
                <p className="text-xs text-gray-500 mt-4">Women, Lounge wear</p>
                <p className="text-xs font-medium mt-2">N4,500.00</p>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 w-2/4 lg:w-1/4   gap-4">
              <img
                src={
                  "https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg"
                }
                alt={""}
                className="object-center object-cover max-h-24"
              />
              <div>
                <p className="text-sm font-medium ">Product Name</p>
                <p className="text-xs text-gray-500 mt-4">Women, Lounge wear</p>
                <p className="text-xs font-medium mt-2">N4,500.00</p>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 w-2/4 lg:w-1/4   gap-4">
              <img
                src={
                  "https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg"
                }
                alt={""}
                className="object-center object-cover max-h-24"
              />
              <div>
                <p className="text-sm font-medium ">Product Name</p>
                <p className="text-xs text-gray-500 mt-4">Women, Lounge wear</p>
                <p className="text-xs font-medium mt-2">N4,500.00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

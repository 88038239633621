import React from "react";
import { HiShoppingBag } from "react-icons/hi2";
export default function ProductImage({ image, alt, className }) {
  return (
    <>
      {image ? (
        <img src={image} alt={alt} className={className} />
      ) : (
        <div>
          <HiShoppingBag className={`${className} opacity- text-gray-200 `} />
        </div>
      )}
    </>
  );
}

import React from "react";

export default function Badge({ status }) {
  return (
    <span
      className={`${
        status === "NEW"
          ? "bg-yellow-100 text-yellow-600 "
          : "bg-green-100 text-green-600 "
      } text-xs px-2 py-[0.8px] rounded-full`}
    >
      {status}
    </span>
  );
}

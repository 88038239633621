import requests from "./httpsServices";
import StorageServices from "./storageServices";

const OrderServices = {
  get() {
    const storeId = this.getSellerStoreId();
    return requests.get(
      `/marketplace/v1/b2b/${storeId}/purchases?page=0&size=3`
    );
  },
  getOrderbyId(id) {
    const storeId = this.getSellerStoreId();
    return requests.get(`/marketplace/v1/b2b/${storeId}/purchase/${id}`);
  },
  getSellerStoreId() {
    const sellerStore = StorageServices.get("active-sellers-store");
    if (sellerStore) {
      return JSON.parse(sellerStore)?.id;
    }
  },
};

export default OrderServices;

import Cookies from "js-cookie";

// localStorage
let activeStorage = window.localStorage; // Cookies; //
const StorageServices = {
  get(key) {
    let item = null;
    if (activeStorage === window.localStorage) {
      item = activeStorage.getItem(key);
    } else {
      item = activeStorage.get(key);
    }
    return item;
  },
  getAsync(key) {
    let item = null;
    if (activeStorage === window.localStorage) {
      item = activeStorage.getItem(key);
    } else {
      item = activeStorage.get(key);
    }
    return Promise.resolve(item);
  },
  set(key, value) {
    if (activeStorage === window.localStorage) {
      activeStorage.setItem(key, value);
    } else {
      activeStorage.set(key, value);
    }
  },
  remove(key) {
    if (activeStorage === window.localStorage) {
      activeStorage.removeItem(key);
    } else {
      activeStorage.remove(key);
    }
  },
  clearAll() {
    Cookies.remove("SB-buyer-token");
    let logdedInEmail = this.get("logged-in-email");
    let keys = this.get("active-buyer-store-keys");

    let remember_me = this.get("SB-remember-me");
    if (activeStorage === window.localStorage) {
      activeStorage.clear();
    } else {
      Object.keys(activeStorage.get()).forEach(function (cookieName) {
        activeStorage.remove(cookieName);
      });
    }

    this.set("logged-in-email", logdedInEmail);
    this.set("SB-remember-me", remember_me);
    this.set("active-buyer-store-keys", keys);
  },
};

export default StorageServices;

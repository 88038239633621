import { useState } from "react";
import { useCart } from "../../providers/CartProvider";
import ProductCard from "./ProductCard";
import QuickView from "./QuickView";

export default function ProductList({ products, extras }) {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const { addToCart } = useCart();

  return (
    <div className="">
      <QuickView
        open={open}
        setOpen={setOpen}
        product={selectedProduct}
        addToCart={addToCart}
        extras={extras}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
      />
      <div className="mx-auto">
        <div className=" grid grid-cols-2 gap-2 sm:grid-cols-3 lg:grid-cols-4 xl:gap-4">
          {products?.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              extras={extras}
              setOpen={setOpen}
              setSelectedProduct={setSelectedProduct}
              addToCart={addToCart}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

import useSellerStore from "../../hooks/useSellerStore";

export default function AuthLayout({ children }) {
  const store = useSellerStore();
  return (
    <>
      <div className="min-h-screen flex divide-x">
        <div className="hidden relative  flex-1 w-1/2 lg:flex flex-col justify-center items-center">
          <div className="rounded-full bg-gray-50 h-24 w-24">
            <img
              src={store?.base_url + store?.logo_key}
              alt=""
              className="w-full"
            />
          </div>
          <h1 className="font-medium text-2xl mt-3">{store?.name}</h1>

          <div className="absolute bottom-0 mb-10">
            <p className="text-center text-sm">Powered by</p>
            <img src="/logo.svg" alt="" className="w-40" />
          </div>
        </div>
        <div className="w-1/2 flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="absolute right-0 left-0 top-0 mb-10 lg:hidden">
            <div className="flex items-center gap-2 px-8 py-2 border border-b">
              <img
                src={store?.base_url + store?.logo_key}
                alt=""
                className="w-8 h-8 rounded-full"
              />
              <h1 className="font-medium text-xl">{store?.name}</h1>
            </div>
          </div>

          {children}

          <div className="absolute right-0 left-0 bottom-0 mb-10 lg:hidden">
            <div className="flex flex-col items-center justify-center">
              <p className="text-center text-sm">Powered by</p>
              <img src="/logo.svg" alt="" className="w-40 text-center" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import Notfoundpng from "../assets/images/404.png";
import unauthorizedbg from "../assets/images/unauthorizedbg.png";

export default function NotFound() {
  return (
    <div
      className="min-h-screen bg-white"
      style={{ backgroundImage: `url(${unauthorizedbg})` }}
    >
      <div>
        <h1 className="text-2xl mt-16 font-bold text-center">
          Whoops, that page is gone.
        </h1>
        <p className="text-center w-4/12 mx-auto mt-5">
          The page you are looking for does not exist, or any other error
          occured. Go back to home page.
        </p>

        <img src={Notfoundpng} alt="" className="w-5/12 mx-auto my-16" />

        <div className="flex justify-center ">
          <button className="flex items-center space-x-2  ring-1 ring-offset-2 ring-black  rounded-full bg-black text-white py-1 px-4 text-sm mt-6">
            <svg
              width="20"
              height="20"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.19531 14.9493L24.6405 14.9493C26.9374 14.9493 28.7995 16.8113 28.7995 19.1083L28.7995 20.6339C28.7995 22.9309 26.9374 24.793 24.6404 24.793L10.2057 24.793"
                stroke="white"
                stroke-width="2.08134"
                stroke-linejoin="round"
              />
              <path
                d="M9.84375 10.5762C8.13521 12.2847 7.17729 13.2426 5.46875 14.9512L9.84375 19.3262"
                stroke="white"
                stroke-width="2.08134"
                stroke-linejoin="round"
              />
            </svg>
            <span>Go to Home page</span>
          </button>
        </div>
      </div>
    </div>
  );
}

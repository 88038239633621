import { ClientJS } from "clientjs";
import requests from "./httpsServices";
import StorageServices from "./storageServices";
const platform = require("platform");

const client = new ClientJS();

const AuthServices = {
  login(username, password) {
    const store = JSON.parse(StorageServices.get("active-sellers-store"));
    const distributor = StorageServices.get("active-buyer-store")
      ? JSON.parse(StorageServices.get("active-buyer-store"))
      : null;

    const payload = {
      app_id: "storebounty",
      business_id:
        distributor?.type === "internal" ? distributor?.id : `${store.id}`,
      username: username,
      email: username,
      platform: "web",
      password: password,
      fingerprint: {
        id: client.getFingerprint(),
        model: platform.description || null,
        os_version: platform.version || null,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
        manufacturer: platform.manufacturer || null,
        is_physical_device: true,
        screen_resolution: client.getCurrentResolution(),
        firebase_device_id: null,
        ip: null,
      },
    };

    if (distributor?.type === "internal") {
      return requests.post(`/accounts/api/v1/auth/users/login`, payload);
    }
    return requests.post(`/accounts/api/v1/auth/customers/login`, payload);
  },

  sendRestOTP(payload) {
    return requests.post("/accounts/api/v1/auth/customers/otp/send", payload);
  },

  ExchangeToken(token) {
    const distributor = StorageServices.get("active-buyer-store")
      ? JSON.parse(StorageServices.get("active-buyer-store"))
      : null;

    return requests.get(
      `/marketplace/v1/user/token/exchange/${distributor?.id}`,
      {
        headers: {
          clientId: "storebounty",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  resetPassword(payload) {
    return requests.post(
      "/accounts/api/v1/auth/customers/password/reset",
      payload
    );
  },
};

export default AuthServices;

import requests from "./httpsServices";
import StorageServices from "./storageServices";

const ImageServices = {
  getImage(id, entity) {
    const sellerStore = this.getSellerStoreId();

    const url = `/marketplace/v1/public/${sellerStore}/media/fetch/${id}?entity=${entity}&size=medium&all=true`;
    return requests.get(url);
  },

  getImages(ids, entity) {
    const sellerStore = this.getSellerStoreId();
    const url = `/marketplace/v1/public/${sellerStore}/media/fetch?entity=${entity}&ids=${ids}&size=medium&all=true`;
    return requests.get(url);
  },

  getSellerStoreId() {
    const sellerStore = StorageServices.get("active-sellers-store");
    if (sellerStore) {
      return JSON.parse(sellerStore)?.id;
    }
  },
};

export default ImageServices;

import React from "react";

export default function InputArea({
  register = () => {},
  name,
  autoComplete = "off",
  ...props
}) {
  return (
    <>
      <input
        {...register(name)}
        {...props}
        autoComplete={autoComplete}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-black focus:shadow-lg focus:border-black sm:text-sm"
      />
    </>
  );
}
